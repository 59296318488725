import { Button, notification, Popconfirm } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowLeft, FaTrash } from 'react-icons/fa';
import { MdTimeline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Processo } from '../../../../entities/processo';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import ProcessoEditar from '../../components/ProcessoEditar';
import ProcessoEtapas from '../../components/ProcessoEtapas';




const ProcessosDetalhes: React.FC = () => {

	const app = useApp();
	const { id } = useParams();

	const [processo, setProcesso] = useState<Processo>();


	const carregaProcesso = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("get", `processo/${id}`);
		if (result?.code === 200)
			setProcesso(result.dados);
	}, [app, id]);


	const excluir = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("delete", `processo/${processo?.id}`);
		if (result?.code === 200) {
			notification.success({
				message: result.mensagem,
				duration: 5,
				placement: 'bottomRight'
			});

			app.goBack();
		}
	}, [app, processo]);


	useEffect(() => {
		carregaProcesso();
	}, [carregaProcesso]);


	return (
		<>
			<h1>
				<MdTimeline />
				Detalhes do Processo
		  	</h1>

			<div className="submenu">
				<Button type='primary' className='button-default' onClick={() => app.goBack()}>
					<FaArrowLeft />
					Voltar
				</Button>	

				<Popconfirm title="Você confirma a EXCLUSÃO deste processo?" placement='bottomLeft' onConfirm={excluir}>
					<Button type='primary' danger>
						<FaTrash />
						Excluir Processo
					</Button>	
				</Popconfirm>			
			</div>

			<ProcessoEditar processo={processo} editable />

			<ProcessoEtapas processoId={processo?.id} processoEtapas={processo?.processoEtapas} />

		</>
	);
}

export default ProcessosDetalhes;