import { Button, Modal, notification, Popconfirm, UploadProps } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaCloudUploadAlt, FaEdit, FaEye, FaFileArchive, FaTrash } from 'react-icons/fa';
import { Evento } from '../../../entities/evento';
import { EventoArquivo } from '../../../entities/evento-arquivo';

import moment from 'moment';
import { Arquivo } from '../../../entities/arquivo';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import Dragger from 'antd/lib/upload/Dragger';
import EventoArquivoEditar from '../../../components/EventoArquivoEditar';

/* ********************
 * Este compodente deve receber um objeto do tipo EVENTO com a propriedade EVENTO ARQUIVOS populada
 * ******************** */

interface EventoArquivosProps {
	evento?: Evento | null;
	permiteExcluir?: boolean;
	onSuccess?(evento: Evento): void;
}


const EventoArquivos: React.FC<EventoArquivosProps> = ({
	evento,
	permiteExcluir = false,
	onSuccess
}) => {

	const app = useApp();

	const [dadosEvento, setDadosEvento] = useState<Evento>({} as Evento);
	const [arquivoEditar, setArquivoEditar] = useState(false);
	const [eventoArquivo, setEventoArquivo] = useState<EventoArquivo | null>(null);


	const tabelaArquivos: ColumnsType<EventoArquivo> = [
		{
			title: "Nome",
			render: (eventoArquivo: EventoArquivo) => (
				<span>{eventoArquivo.arquivo.nome}</span>
			)
		},
		{
			title: "Tipo",
			render: (eventoArquivo: EventoArquivo) => (
				<span>{eventoArquivo.eventoArquivoTipo.nome}</span>
			)
		},	
		{
			title: "Formato",
			render: (eventoArquivo: EventoArquivo) => (
				<span>{eventoArquivo.arquivo.tipo}</span>
			)
		},	
		{
			title: "Data de Cadastro",
			render: (eventoArquivo: EventoArquivo) => (
				<span>{moment(eventoArquivo.arquivo.created).format("DD/MM/YYYY -- HH:mm:ss")}</span>
			)
		},		
		{
			title: "",
			render: (eventoArquivo: EventoArquivo) => (
				<>
					{permiteExcluir && (
						<Popconfirm  placement="topRight" title="Você confirma a EXCLUSÃO deste arquivo?" onConfirm={() => excluir(eventoArquivo)}>
							<Button size='small' danger type='primary' className='button-icon'>
								<FaTrash />							
							</Button>
						</Popconfirm>
					)}					
					
					<Button type='primary' className='button-icon' onClick={() => editarArquivo(eventoArquivo)}>
						<FaEdit />						
					</Button>
					<Button type='primary' className='button-icon button-default' onClick={() => visualizar(eventoArquivo.arquivo)}>
						<FaEye />					
					</Button>
				</>
			)
		},
	];



	// ***** Configurações do upload PRIVADO
	const propsPrivado: UploadProps = {
		name: 'arquivo',		
		multiple: true,				
		action: `${process.env.REACT_APP_URL_API}/arquivo/upload`,
		headers: { 
			"Tenant": process.env.REACT_APP_TENANT ? process.env.REACT_APP_TENANT : ``,
			"Authorization": `Bearer ${sessionStorage.getItem("token")}`
		},
		data: { privado: true },
		showUploadList: true
	};



	const cadastraArquivo = useCallback(async (info:any) => {
		if (info.file.status === "done") {		
			let arquivoId = info.file.response.dados.id;
			
			let dadosArquivo = {
				eventoId: dadosEvento?.id,
				eventoArquivoTipoId: 5,
				arquivoId
			};

			try {
				let result: Result | null = await app.ajaxApi("POST", "evento/arquivo", dadosArquivo);				
				
				let aux: Evento = dadosEvento;
				aux?.eventoArquivos.push(result?.dados);
				setDadosEvento(aux);
				if (onSuccess)
					onSuccess(aux);

			} catch (e) {
				console.log(e);
			}
		}
	}, [app, dadosEvento, onSuccess]);



	// Visualizar arquivo
	const visualizar = useCallback(async (arquivo: Arquivo) => {
		if (!arquivo.privado) {
			// ***** Arquivo PÚBLICO
			window.open(`${process.env.REACT_APP_CLOUDFRONT}/${arquivo.source}`, "_blank");
		} else {
			// ***** Arquivo PRIVADO
			let result: Result | null = await app.ajaxApi("get", `arquivo/privado/${arquivo.id}`);
			if (result?.code === 200) {
				window.open(`${result.dados.url}`, "_blank");						
			}			
		}
	}, [app]);



	const editarArquivo = useCallback((eventoArquivo: EventoArquivo) => {
		setEventoArquivo(eventoArquivo);
		setArquivoEditar(true);
	}, []);


	const atualizaArquivo = useCallback((eventoArquivo: EventoArquivo) => {
		let eventoAux: Evento = dadosEvento;
		eventoAux?.eventoArquivos.forEach((arquivo: EventoArquivo) => {
			if (arquivo.id === eventoArquivo.id)
				arquivo = eventoArquivo;
		});

		setDadosEvento(eventoAux);
		if (onSuccess)
			onSuccess(eventoAux);

	}, [onSuccess, dadosEvento]);


	const excluir = useCallback(async (eventoArquivo: EventoArquivo) => {
		let result: Result | null = await app.ajaxApi("delete", `evento/arquivo/${eventoArquivo.id}`);
		if (result?.code === 200) {
			if (onSuccess)
				onSuccess({} as Evento);

			notification.success({
				message: "Arquivo do evento excluído",
				duration: 5,
				placement: 'bottomRight'
			});
		}
		
	}, [app, onSuccess]);



	useEffect(() => {
		if (evento)
			setDadosEvento(evento);
	}, [evento]);
	


	return (
		<>
			<div className="box">
				<h2>
					<FaFileArchive />
					Arquivos
				</h2>

				<Dragger {...propsPrivado} height={100} onChange={cadastraArquivo} style={{ backgroundColor: "#daebff", marginTop: "15px" }}>
					<div className='upload'>
						<FaCloudUploadAlt size={40} color="#4794f2" />
						<h2 style={{ color: "#4794f2", background: 'none' }}>Enviar arquivos</h2>
					</div>
					<p>Clique aqui ou arraste o arquivo para esta área para fazer upload</p>						
				</Dragger>

				<Table columns={tabelaArquivos} dataSource={dadosEvento?.eventoArquivos} />
			</div>

			<Modal visible={arquivoEditar} closable footer={false} onCancel={() => setArquivoEditar(false)}>
				<EventoArquivoEditar eventoArquivo={eventoArquivo} onClose={() => setArquivoEditar(false)} onSuccess={atualizaArquivo} />
			</Modal>
		</>
	);
}

export default EventoArquivos;