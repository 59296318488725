import { Col, Row } from 'antd';
import React from 'react';
import { FaHome } from 'react-icons/fa';
import ArquivoInfo from '../../../../components/ArquivoInfo';
import EventoDashboard from '../../components/EventoDashboard';

const Painel: React.FC = () => {



  return (
	  <>
	  	<h1>
			  <FaHome />
			  Painel de Controle
		</h1>

		<Row gutter={25}>
			<Col span={24} md={12}>
				<EventoDashboard />
			</Col>

			<Col span={24} md={12}>
				<ArquivoInfo />				
			</Col>
		</Row>
		  
	  </>
  );
}

export default Painel;