import { AutoComplete, Button, Col, DatePicker, Form, Input, notification, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';

import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa';
import { MdOutlineEventAvailable } from 'react-icons/md';
import { Cadastro } from '../../../entities/cadastro';
import { Evento } from '../../../entities/evento';
import { Result } from '../../../interfaces/Result';
import InputCnpj from '../../../masks/InputCnpj';
import InputCpf from '../../../masks/InputCpf';
import InputFloat from '../../../masks/InputFloat';
import InputTelefone from '../../../masks/InputTelefone';
import { useApp } from '../../../providers/AppProvider';


interface EventoNovoProps {
	onSuccess?(evento: Evento): void;
	onClose?(): void;
}


const EventoNovo: React.FC<EventoNovoProps> = ({
	onSuccess,
	onClose
}) => {

	const { Option } = Select;
	const [form] = useForm();
	const app = useApp();
	const [clienteSelecionado, setClienteSelecionado] = useState(false);
	const [parceiros, setParceiros] = useState<Cadastro[]>([]);

	let timeout: any = null;
	const [clientes, setClientes] = useState<Cadastro[]>([]);
	const { Option: OptionAutoComplete } = AutoComplete;

	const [cadastroTipo, setCadastroTipo] = useState<number>();
	const [loading, setLoading] = useState(false);


	const buscarCadastro = async (busca: string) => {		
		clearTimeout(timeout);

		let dadosForm: any = {			
			email: "",
			telefone: "",
			celular: "",
			tipo: "",
			cpf: "",
			cnpj: ""		
		};
		form.setFieldsValue(dadosForm);
		setClienteSelecionado(false);

		if (!busca) {
			setClientes([]);
			return;
		}			

		timeout = setTimeout(async () => {
			let result: Result | null = await app.ajaxApi("get", `cadastro/buscar/${busca}`);
			if (result?.code === 200) {
				setClientes(result.dados);
			}
		}, 700);
	};


	const cadastroSelecionado = useCallback(async (valor: any) => {
		let cadastro: Cadastro | undefined = clientes.find((item: Cadastro) => {
			if (item.id === valor)
				return true;

			return false;
		});

		if (cadastro) {
			let dadosForm: any = {
				nomeCadastro: cadastro.nome,
				emailCadastro: cadastro.email,
				telefoneCadastro: cadastro.telefone,
				celularCadastro: cadastro.celular,
				tipoCadastro: cadastro.tipo		
			};

			if (cadastro.tipo)
				setCadastroTipo(cadastro.tipo);

			if (cadastro.tipo === 1)
				dadosForm["cpf"] = cadastro.documento;
			
			if (cadastro.tipo === 2)
				dadosForm["cnpj"] = cadastro.documento;
			
			form.setFieldsValue(dadosForm);
			setClienteSelecionado(true);
		}
	}, [form, clientes]);


	const salvar = useCallback(async (formData: any) => {
		setLoading(true);

		let dadosForm: any = {
			...formData
		};

		if (formData.tipoCadastro === 1)
			dadosForm["documento"] = formData.cpf;

		if (formData.tipoCadastro === 2)
			dadosForm["documento"] = formData.cnpj;

		console.log(dadosForm);

		let result: Result | null = await app.ajaxApi('post', 'admin/evento', dadosForm);
		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);

			if (onClose)
				onClose();

			notification.success({
				message: "Evento cadastrado",
				duration: 5,
				placement: "bottomRight"
			});
		}

		setLoading(false);
	},[onSuccess, onClose, app]);


	const carregaParceiros = useCallback(async () => {
		let result: Result | null = await app.ajaxApi('get', 'cadastro/listar/parceiros');
		if (result?.code === 200) {
			setParceiros(result.dados);
		}
	}, [app]);


	useEffect(() => {
		carregaParceiros();
	}, [carregaParceiros]);



	return (
		<>
			<h2>
				<MdOutlineEventAvailable />
				Novo Evento
			</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				<Row gutter={25}>
					<Col md={12}>
						<FormItem label="Nome do Cliente" name="nomeCadastro" rules={[
							{ required: true, message: "Informe o nome do cliente" }
						]}>							
							<AutoComplete style={{ width: '100%' }} onSelect={cadastroSelecionado} onSearch={buscarCadastro}>
								{clientes.map((cadastro: Cadastro) => (
									<OptionAutoComplete key={cadastro.id} value={cadastro.id}>
										{cadastro.nome}
									</OptionAutoComplete>
								))}								
							</AutoComplete>
						</FormItem>
					</Col>					

					<Col md={12}>
						<FormItem label="E-mail" name="emailCadastro" rules={[
							{ required: true, message: "Informe o e-mail" }
						]}>
							<Input disabled={clienteSelecionado} />
						</FormItem>
					</Col>

					<Col md={12}>
						<FormItem label="Tipo" name="tipoCadastro">
							<Select disabled={clienteSelecionado} style={{ width: '100%' }} onChange={(value) => setCadastroTipo(value)}>
								<Option value={1}>Pessoa Física</Option>
								<Option value={2}>Pessoa Jurídica</Option>
							</Select>
						</FormItem>
					</Col>

					<Col md={12}>
						{cadastroTipo === 1 && (
							<FormItem label="CPF" name="cpf">
								<InputCpf disabled={clienteSelecionado} />
							</FormItem>
						)}
						
						{cadastroTipo === 2 && (
							<FormItem label="CNPJ" name="cnpj">
								<InputCnpj disabled={clienteSelecionado} />
							</FormItem>
						)}
					</Col>

					<Col md={12}>
						<FormItem label="Telefone" name="telefoneCadastro">
							<InputTelefone disabled={clienteSelecionado} />
						</FormItem>
					</Col>

					<Col md={12}>
						<FormItem label="Celular" name="celularCadastro" rules={[
							{ required: true, message: "Informe o celular do cliente" }
						]}>
							<InputTelefone disabled={clienteSelecionado} />
						</FormItem>
					</Col>
				</Row>

				<hr />

				<Row gutter={25}>					
					<Col md={8}>
						<FormItem label="Data do Evento" name="dataInicio" rules={[
							{ required: true, message: "Informe a data do evento" }
						]}>
							<DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
						</FormItem>
					</Col>

					<Col md={8}>
						<FormItem label="Local" name="local" rules={[
							{ required: true, message: "Informe o local do evento" }	
						]}>
							<Input />
						</FormItem>
					</Col>

					<Col md={8}>
						<FormItem label="Tipo do Evento" name="tipo">
							<Input placeholder='Casamento, 15 Anos, etc...' />
						</FormItem>
					</Col>

					<Col md={8}>
						<FormItem label="Nome do Evento" name="nome">
							<Input />
						</FormItem>
					</Col>

					<Col md={8}>
						<FormItem label="Produtor" name="produtor">
							<Input />
						</FormItem>
					</Col>

					<Col md={8}>
						<FormItem label="Valor" name="valor">
							<InputFloat />
						</FormItem>
					</Col>

					<Col md={12}>
						<FormItem label="Status" name="eventoStatusId" rules={[
							{ required: true, message: "Informe o status do evento" }
						]}>
							<Select style={{ width: '100%' }}>
								<Option value={1}>Novo</Option>
								<Option value={2}>Pendente</Option>
								<Option value={3}>Confirmado</Option>
								<Option value={4}>Concluído</Option>
								<Option value={5}>Cancelado</Option>
							</Select>
						</FormItem>

						<FormItem label="Parceiro" name="cadastroIdParceiro">
							<Select style={{ width: '100%' }} allowClear>
								{parceiros.map((parceiro: Cadastro) => (
									<Option key={parceiro.id} value={parceiro.id}>{parceiro.nome}</Option>
								))}
							</Select>
						</FormItem>
					</Col>

					<Col md={12}>
						<FormItem label="Observações" name="observacao">
							<TextArea rows={5} />
						</FormItem>
					</Col>
				</Row>

				<div className="acoes">
					{onClose && (
						<Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>	
					)}
					<Button type='primary' className='button-success' icon={<FaCheck />} htmlType="submit" loading={loading}>Salvar</Button>
				</div>
				
			</Form>
		</>
	);
}

export default EventoNovo;