import { Button, Form } from 'antd';
import Input from 'antd/lib/input/Input';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { Cadastro } from '../../../entities/cadastro';
import { useApp } from '../../../providers/AppProvider';

interface ClienteResumoProps {
	cadastroCliente?: Cadastro
}

const ClienteResumo: React.FC<ClienteResumoProps> = ({
	cadastroCliente
}) => {

	const { toUrl } = useApp();

  	return (
		<div className='box' style={{ marginBottom: '25px' }}>
			<h2>Dados do Cliente</h2>

			<Form layout='vertical'>
				<Form.Item label="Nome">
					<Input readOnly value={cadastroCliente?.nome} />
				</Form.Item>

				<Form.Item label="E-mail">
					<Input readOnly value={cadastroCliente?.email} />
				</Form.Item>

				<Form.Item label="Celular">
					<Input readOnly value={cadastroCliente?.celular} />
				</Form.Item>

				<div className='acoes'>
					<Button size='small' className='button-default' type='primary' onClick={() => toUrl(`/admin/cadastro/detalhes/${cadastroCliente?.id}`)}>
						<FaSearch />
						Ver dados do cliente
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default ClienteResumo;