import { Button, Form, Input } from 'antd';
import Password from 'antd/lib/input/Password';
import React, { useState } from 'react';
import BoxError from '../../../components/BoxError';
import FormProps from '../../../interfaces/FormProps';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import { AiOutlineLogin } from 'react-icons/ai';
import { Link } from 'react-router-dom';


const LoginForm: React.FC<FormProps> = ({
	onSuccess
}) => {


	const app = useApp();

	const [loading, setLoading] = useState(false);	
	const [error, setError] = useState<Result | null>(null);


	const submit = async (dados: any) => {
		
		setLoading(true);
		setError(null);
		let result: Result | null = await app.ajaxApi("post", "auth/login", dados, false);
		
		if (result?.code !== 200) {
			setError(result);
			setLoading(false);
			return;
		}
		
		let data: any = result.dados;
		app.login(data.nome, data.email, data.areas, data.token);
		setLoading(false);

		if (onSuccess)
			onSuccess(result);
	}


	return (
		<>
			<BoxError result={error} />

			<Form layout='vertical' onFinish={submit}>
				<Form.Item label="E-mail" name="email" rules={[
					{ required: true, message: "Informe o e-mail" }
				]}>
					<Input autoFocus autoComplete='off' autoSave='off' />
				</Form.Item>

				<Form.Item label="Senha" name="senha" rules={[
					{ required: true, message: "Informe a senha" }
				]}>
					<Password autoComplete='off' autoSave='off' />
				</Form.Item>

				<div className="acoes" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Link to="/auth/esqueci-minha-senha">Esqueci minha senha</Link>
					<Button htmlType='submit' type='primary' icon={<AiOutlineLogin />} loading={loading}>Entrar</Button>				
				</div>				
			</Form>
		</>
	);
}

export default LoginForm;