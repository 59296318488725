import { Button, Col, Form, Input, notification, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Processo } from '../../../entities/processo';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';


interface ProcessoEditarProps {
	processo?: Processo,
	onSuccess?(processo: Processo): void,
	editable?: boolean 
}


const ProcessoEditar: React.FC<ProcessoEditarProps> = ({
	processo,
	onSuccess,
	editable = false
}) => {

	const [form] = useForm();
	const { Text } = Typography;
	const [loading, setLoading] = useState(false);
	const app = useApp();
	


	const salvar = useCallback(async (formData: any) => {
		setLoading(true);
		let result: Result | null = await app.ajaxApi("put", `processo/${processo?.id}`, formData, true);
		if (result?.code === 200) {
			notification.success({
				message: result.mensagem,
				duration: 5,
				placement: "bottomRight"
			});
		}
		setLoading(false);
	}, [app, processo]);



	useEffect(() => {
		if (processo)
			form.setFieldsValue(processo);
	}, [processo, form]);


	return (
		<div className="box">
			<h2>Dados Gerais do Processo</h2>
			
			<Form form={form} layout="vertical" onFinish={salvar}>
				<Row gutter={25}>
					<Col md={14}>
						<FormItem label="Nome" name="nome">
							<Input />
						</FormItem>			

						<FormItem label="Descrição" name="descricao">
							<TextArea rows={9} />
						</FormItem>			
					</Col>

					<Col md={10}>
						<FormItem label="ID" name="id">
							<Text copyable>{processo?.id}</Text>
						</FormItem>			

						<FormItem label="Código" name="codigo">
							<Input disabled />
						</FormItem>			

						<FormItem label="Data de Cadastro" name="created">
							<Input disabled />
						</FormItem>			

						<FormItem label="Última Alteração" name="modified">
							<Input disabled />
						</FormItem>			
					</Col>
				</Row>

				{editable && (
					<div className="acoes">
						<Button type="primary" className='button-success' icon={<FaCheck />} loading={loading} htmlType="submit">Salvar</Button>
					</div>
				)}
			</Form>
			
		</div>
	);
}

export default ProcessoEditar;