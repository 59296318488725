import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { ProcessoEtapa } from '../../../entities/processo-etapa';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Alert, Button, Modal, notification, Popconfirm } from 'antd';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { BsArrowDownUp } from 'react-icons/bs';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import { MdTimeline } from 'react-icons/md';
import ProcessoEtapaEditar from './ProcessoEtapaEditar';


// ***** Define interfaces
interface ProcessoEtapasProps {
	processoId?: string,
	processoEtapas?: ProcessoEtapa[],	
}

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
	index: number;
	moveRow: (dragIndex: number, hoverIndex: number) => void;
}

// ***** Define constantes
const type = 'DraggableBodyRow';



const ProcessoEtapas: React.FC<ProcessoEtapasProps> = ({
	processoEtapas,	
	processoId
}) => {


	const app = useApp();
	const [loadingPosicao, setLoadingPosicao] = useState(false);
	
	const [showProcessoEtapaEditar, setShowProcessoEtapaEditar] = useState(false);
	const [etapaSelecionada, setEtapaSelecionada] = useState<ProcessoEtapa | null>(null)
	
	const [etapas, setEtapas] = useState<ProcessoEtapa[]>([]);



	// ***** Configura o arrasta e solta para a ordenação na tabela
	const DraggableBodyRow = ({
		index,
		moveRow,
		className,
		style,
		...restProps
	}: DraggableBodyRowProps) => {

		const ref = useRef<HTMLTableRowElement>(null);
		const [{ isOver, dropClassName }, drop] = useDrop({
		  accept: type,
		  collect: monitor => {
			const { index: dragIndex } = monitor.getItem() || {};
			if (dragIndex === index) {
			  return {};
			}
			return {
			  isOver: monitor.isOver(),
			  dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
			};
		  },
		  drop: (item: { index: number }) => {
			moveRow(item.index, index);
		  },
		});
		const [, drag] = useDrag({
		  type,
		  item: { index },
		  collect: monitor => ({
			isDragging: monitor.isDragging(),
		  }),
		});
		drop(drag(ref));
	  
		return (
		  <tr
			ref={ref}
			className={`${className}${isOver ? dropClassName : ''}`}		
			style={{ cursor: 'move' }}	
			{...restProps}
		  />
		);
	};


	// ***** Função executada quando é alterado a ordem de um item na tabela
	const moveRow = useCallback(
		(dragIndex: number, hoverIndex: number) => {
		  const dragRow = etapas[dragIndex];
		  setEtapas(
			update(etapas, {
			  $splice: [
				[dragIndex, 1],
				[hoverIndex, 0, dragRow],
			  ],
			}),
		  );
		  //salvaPosicao();
	}, [etapas]);
	

	// ***** Configura as colução e botões de ação da tabela
	const tabelaEtapas: ColumnsType<ProcessoEtapa> = [
		{
			title: "Nome",
			dataIndex: "nome"			
		},
		{
			title: "Descrição",
			dataIndex: "descricao"			
		},
		{
			title: "Prazo",
			render: (etapa: ProcessoEtapa) => (
				<>
					{etapa.prazo ? (
						<span>{etapa.prazo} dias</span>
					) : (
						<span>Não definido</span>
					)}
				</>
			)
		},
		{
			render: (etapa: ProcessoEtapa) => (
				<>
					{etapa.subProcesso && (
						<Button size='small' type='primary' className='button-default' onClick={() => app.toUrl(`admin/processos/detalhes/${etapa.subProcessoId}`)}>
							<MdTimeline />
							Ver Subprocesso
						</Button>
					)}
					
					<Popconfirm placement='topLeft' title={(<span>Você confirma a EXCLUSÃO da estapa: <strong>{etapa.nome}</strong></span>)} onConfirm={() => excluir(etapa)}>
						<Button size='small' type='primary' danger>
							<FaTrash />
							Excluir
						</Button>
					</Popconfirm>

					<Button size='small' type='primary' onClick={() => editarEtapa(etapa)}>
						<FaEdit />
						Editar
					</Button>
				</>
			)
		}
	];



	

	// ***** Função para excluir uma etapa do processo
	const excluir = useCallback(async (etapa: ProcessoEtapa) => {
		let result: Result | null = await app.ajaxApi("delete", `processo/etapa/${etapa.id}`);
		if (result?.code === 200) {
			let itemRemove;		
			etapas.forEach((item: ProcessoEtapa, index: number) => {
				if (item.id === etapa.id)
					itemRemove = index;
			});
			
			if (itemRemove !== undefined) {
				setEtapas(
					update(etapas, {
					$splice: [
						[itemRemove, 1], [itemRemove, 0]
					]
					}),
				);
			}
		}		
	}, [etapas, app]);


	// ***** Função para salvar as posições das etapas definidas na tabela
	const salvaPosicao = useCallback(async () => {
		setLoadingPosicao(true);

		let ids: string[] = [];
		etapas.forEach((etapa: ProcessoEtapa) => {
			ids.push(etapa.id);
		});

		let result: Result | null = await app.ajaxApi("put", `processo/${processoId}/posicao-etapas`, {
			etapasIds: ids
		});
		if (result?.code === 200) {
			notification.success({
				message: "Posições salvas",
				duration: 5,
				placement: "bottomRight"
			});
		}

		setLoadingPosicao(false);
	}, [etapas, app, processoId]);


	// ***** Ação para abrir o modal de NOVA ETAPA
	const novaEtapa = useCallback(() => {
		setShowProcessoEtapaEditar(true);		
		setEtapaSelecionada({
			ordem: processoEtapas?.length
		} as ProcessoEtapa);
	}, [processoEtapas]);


	// ***** Ação para abrir o modal de EDIÇÃO DA ETAPA
	const editarEtapa = useCallback((processoEtapa: ProcessoEtapa) => {
		setShowProcessoEtapaEditar(true);
		setEtapaSelecionada(processoEtapa);
	}, []);


	// ***** Atualiza ETAPAS após inclusão
	const atualizaEtapas = useCallback((processoEtapa: ProcessoEtapa) => {
		let itemAtualizado;		
		etapas.forEach((item: ProcessoEtapa, index: number) => {
			if (item.id === processoEtapa.id)
				itemAtualizado = index;
		});
		
		if (itemAtualizado !== undefined) {
			setEtapas(
				update(etapas, {
				$splice: [
						[itemAtualizado, 1],
						[itemAtualizado, 0, processoEtapa],
					],
				}),
			);
		} else {
			setEtapas(
				update(etapas, {
					$push: [processoEtapa]
				}),
			);
		}
	}, [etapas]);



	
	
	// ***** Inicialização do componente
	useEffect(() => {
		if (processoEtapas)
			setEtapas(processoEtapas)
	}, [processoEtapas]);


	return (
		<div className="box">
			<h2>Etapas do Processo</h2>

			<div className="submenu">
				<Button type='primary' className='button-success' onClick={novaEtapa}>
					<FaPlus />
					Nova Etapa
				</Button>
			</div>

			<Alert showIcon message={(
				<>
					Para ordenar as etapas basta clicar e posicionar no local desejado, e após clicar em SALVAR POSIÇÕES
				</>
			)} />
			
			<DndProvider backend={HTML5Backend}>
				<Table pagination={false} columns={tabelaEtapas} dataSource={etapas} 
					components={{
						body: {
						  row: DraggableBodyRow,
						},
					}} 
					onRow={(_, index) => {
						const attr = {
							index,
							moveRow,
						};
						return attr as React.HTMLAttributes<any>;
					}}
				/>	
			</DndProvider>
			
			{etapas?.length > 0 && (
				<Button style={{ marginTop: '15px' }} onClick={salvaPosicao} className='button-success' icon={<BsArrowDownUp />} loading={loadingPosicao}>				
					Salvar Posições
				</Button>
			)}

			<Modal footer={false} destroyOnClose maskClosable={false} visible={showProcessoEtapaEditar} onCancel={() => setShowProcessoEtapaEditar(false)}>
				<ProcessoEtapaEditar processoId={processoId} processoEtapa={etapaSelecionada} onClose={() => setShowProcessoEtapaEditar(false)} onSucess={atualizaEtapas} />
			</Modal>		
		</div>
	);
}

export default ProcessoEtapas;