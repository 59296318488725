/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaHandsHelping, FaHome, FaSignOutAlt, FaUser } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useApp } from '../../providers/AppProvider';

import Logo from '../../assets/imgs/logo.png';

import './LayoutParceiro.css';


interface LayoutAdminProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const LayoutParceiro: React.FC<LayoutAdminProps> = ({ children }) => {

	const app = useApp();
	const navigate = useNavigate();

	const [nome, setNome] = useState<string | null>();

	useEffect(() => {
		setNome(sessionStorage.getItem("parceiroNome"));
	}, []);	


	const logout = useCallback(() => {
		app.logout();
		navigate("/");
	}, [app, navigate]);


	return (
		<div className='admin'>
			<header>
				<div className='container'>
					<div className='logo'>
						
					</div>

					<nav>
						
						<Link to="/parceiro/inicio">
							<FaHome />
							Início
						</Link>

						<Link to="/meus-dados">
							<FaUser />
							Meus Dados
						</Link>

						<a onClick={logout}>
							<FaSignOutAlt />
							Sair
						</a>
					</nav>
				</div>
			</header>

			<main>
				<section className='area-parceiro'>
					<Row gutter={25} style={{ marginBottom: '25px' }}>
						<Col lg={24}>
							<div className="info">
								<div className="logo">
									<img src={Logo} alt="Logo TSG Tech" />
								</div>
								
								<h1>
									<p>
										Programa de Parceiros
										<strong>{nome}</strong>
									</p>
									<FaHandsHelping size={64} />
								</h1>
							</div>
						</Col>
					</Row>
					{children}
				</section>
			</main>

			<footer>
				SDI Digital &copy; 2022
			</footer>			
		</div>
	);
}

export default LayoutParceiro;