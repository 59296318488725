import { Input } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { InputCustomProps } from '../interfaces/InputCustomProps';

import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 

const InputTelefone: React.FC<InputCustomProps> = ({
	value,
	onChange,
	disabled = false
}) => {

	useEffect(() => {		
		$(".maskTelefone").mask((valor: string) => {			
			return valor.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
		}, {
			onKeyPress: function(e: string, event: object, currentField: any, options: any){	
				currentField.mask((e: any) => {					
					return e.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
				}, options)		
			  }
		});
	}, []);


	const atualiza = useCallback((e: any) => {
		if (onChange)
			onChange(e.target.value);
			
	}, [onChange]);



	return (
		<>
			<Input disabled={disabled} className='maskTelefone' onChange={(e) => atualiza(e)} onBlur={(e) => atualiza(e)} value={value} onKeyUp={(e) => atualiza(e)} />
		</>
	);
}

export default InputTelefone;