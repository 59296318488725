import { Button, Form, Input, notification } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useState } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { Processo } from '../../../entities/processo';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';


interface ProcessoNovoProps {
	data?: Processo,
	onSuccess?(processo: Processo): void,
	onClose?(): void
}


const ProcessoNovo: React.FC<ProcessoNovoProps> = ({
	data,
	onSuccess,
	onClose
}) => {

	const app = useApp();
	const [loading, setLoading] = useState(false);

	const salvar = useCallback(async (formData: any) => {
		setLoading(true);

		let result: Result | null = await app.ajaxApi("post", "processo", formData);
		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);

			if (onClose)
				onClose();

			notification.success({
				message: "Processo cadastrado",
				duration: 5,
				placement: "bottomRight"
			});
		}

		setLoading(false);
	}, [app, onSuccess, onClose]);



	return (
		<>
			<h2>
				<FaEdit />
				Editar Processo
			</h2>

			<Form layout='vertical' onFinish={salvar}>
				<FormItem label="Nome" name="nome" rules={[
					{ required: true, message: "Informe o nome do processo" }
				]}>
					<Input />
				</FormItem>	

				<FormItem label="Descrição" name="descricao">
					<TextArea rows={6} />
				</FormItem>	

				<div className="acoes">
					{onClose && (
						<Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>
					)}
					<Button type='primary' className='button-success' icon={<FaCheck />} htmlType="submit" loading={loading}>Salvar</Button>
				</div>
			</Form>
		</>
	);
}

export default ProcessoNovo;