import { Alert, Button, Col, Empty, Form, Input, Row, Switch, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowLeft, FaEdit, FaSearch } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Cadastro } from '../../../../entities/cadastro';
import CadastroEditarForm from '../../forms/Cadastro/CadastroEditarForm';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import moment from 'moment';
import { Evento } from '../../../../entities/evento';
import { ColumnsType } from 'antd/lib/table';
import EventoStatus from '../../../../components/EventoStatus';

const numberFormat = require('number_format-php');


const CadastroDetalhes: React.FC = () => {

	const { id } = useParams();
	const app = useApp();


	const [cadastro, setCadastro] = useState<Cadastro>();
	const [eventos, setEventos] = useState<Evento[]>([]);



	const tabelaEventos: ColumnsType<Evento> = [
		{
			title: "Data do Evento",
			render: (evento: Evento) => (
				<strong>
					{moment(evento.dataInicio).format("DD/MM/YYYY")}
				</strong>
			)
		},
		{
			title: "Nome do Evento",
			render: (evento: Evento) => (
				<>
					{evento.nome ? evento.nome : <i>Não informado</i>}
				</>
				
			)
		},
		{
			title: "Local",
			dataIndex: "local"
		},
		{
			title: "Valor",
			render: (evento: Evento) => (
				<>
					{evento.valor !== 0 ? <strong>R$ {numberFormat(evento.valor, 2, ',', '.')}</strong> : <strong style={{ color: '#BB0000' }}>Não definido</strong>}
				</>
				
			)
		},
		{
			title: "Status",
			render: (evento: Evento) => (
				<EventoStatus eventoStatusId={evento.eventoStatusId} eventoStatusNome={evento.eventoStatusNome} />
			)
		},
		{
			title: "",
			render: (evento: Evento) => (
				<>
					<Button size='small' className='button-default' type='primary' onClick={() => app.toUrl(`/admin/eventos/detalhes/${evento.id}`)}>
						<FaSearch />
						Detalhes
					</Button>
				</>
			)
		}
	]



	const selecionaCadastro = useCallback(async () => {
		try {
			let result: Result | null = await app.ajaxApi("GET", `cadastro/${id}`);			
			setCadastro(result?.dados);
			if (result?.dados.eventos !== null)
				setEventos(result?.dados.eventos);

		} catch (e) {

		}
	}, [app, id]);

	
	useEffect(() => {
		selecionaCadastro();
	}, [selecionaCadastro]);


	return (
		<>
			<h1>
				  <FaEdit />
				  Detalhes do Cadastro
			</h1>
	
			<div className='submenu'>
				<nav>
					<Button className='button-default' type='primary' icon={<FaArrowLeft />} onClick={app.goBack}>Voltar</Button>	
				</nav>
			</div>

			<div>
				<div className='box' style={{ marginBottom: '25px' }}>
					<h2>Dados Gerais</h2>
					<CadastroEditarForm data={cadastro} />
				</div>

				<Row gutter={25}>
					<Col md={8}>
						<div className="box">
							<h2>Login de Acesso</h2>

							{cadastro?.cadastroLogin ? (
								<>
									<Alert type='success' message="Login habilitado" showIcon style={{ marginBottom: '15px' }} />

									<Form layout='vertical'>
										<Form.Item label="Último Acesso">
											<Input title={moment(cadastro?.cadastroLogin.ultimoAcesso).format("DD/MM/YYYY - HH:mm:ss")} value={cadastro?.cadastroLogin.ultimoAcesso ? moment(cadastro?.cadastroLogin.ultimoAcesso).fromNow() : 'Nenhum acesso'} readOnly />
										</Form.Item>

										<Form.Item label="Número de Tentativas Inválidas">
											<Input value={cadastro?.cadastroLogin.tentativas} readOnly />
										</Form.Item>
									</Form>
								</>								
							) : (
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									<Empty style={{ marginBottom: '25px' }} />
									<Button type='primary'>Criar Login</Button>
								</div>
							)}
						</div>
					</Col>


					{cadastro?.parceiro && (
						<Col md={8}>
							<div className="box">
								<h2>Parceiro</h2>

								<div className="lista-switch">
									<div className="item">
										<p>Acesso ao Sistema</p>
										<Switch checked={cadastro.parceiro.acessoSistema} />
									</div>
								</div>

								<Form layout='vertical'>
									<Form.Item label="Data de cadastro">
										<Input value={moment(cadastro?.parceiro.created).format("DD/MM/YYYY - HH:mm:ss").toString()} readOnly />
									</Form.Item>

									<Form.Item label="Última alteração">
										<Input value={moment(cadastro?.parceiro.modified).format("DD/MM/YYYY - HH:mm:ss").toString()} readOnly />
									</Form.Item>
								</Form>
								
							</div>
						</Col>
					)}


					{cadastro?.cliente && (
						<Col md={8}>
							<div className="box">
								<h2>Cliente</h2>

								<Form layout='vertical'>
									<Form.Item label="Data de cadastro">
										<Input value={moment(cadastro?.cliente.created).format("DD/MM/YYYY - HH:mm:ss").toString()} readOnly />
									</Form.Item>

									<Form.Item label="Última alteração">
										<Input value={moment(cadastro?.cliente.modified).format("DD/MM/YYYY - HH:mm:ss").toString()} readOnly />
									</Form.Item>
								</Form>
								
							</div>
						</Col>
					)}	
				</Row>

				{eventos.length > 0 && (
					<Row style={{ marginTop: '25px' }}>
						<Col md={24}>
							<div className="box">
								<h2>Eventos</h2>
								<Table columns={tabelaEventos} dataSource={eventos} />
							</div>
						</Col>
					</Row>
				)}
				
			</div>
			
		  </>
	  );
}

export default CadastroDetalhes;