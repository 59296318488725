import { Button, Col, Row, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { MdOutlineEventAvailable } from 'react-icons/md';
import { Evento } from '../../../../entities/evento';

import NovoEventoForm from '../../forms/NovoEventoForm/NovoEventoForm';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';

import './Inicio.css';


const Inicio: React.FC = () => {

	const app = useApp();

	const [loadingEventos, setLoadingEventos] = useState(false);
	const [eventos, setEventos] = useState<Evento[]>();


	// ***** Configura a tabela de eventos
	const tabelaEvento: ColumnsType<Evento> = [
		{
			title: "Data do Evento",
			render: (evento: Evento) => (
				<>
					<strong>{moment(evento.dataInicio).format("DD/MM/YYYY")}</strong>	
				</>
			)
		},
		{
			title: "Cliente",
			render: (evento: Evento) => (
				<>
					{evento.cadastroCliente.nome}
				</>
			)
		},
		{
			title: "Status",
			render: (evento: Evento) => (
				<>
					{evento.eventoStatusId === 1 && <Tag color="purple">Novo</Tag>}
					{evento.eventoStatusId === 2 && <Tag color="warning">Pendente</Tag>}
					{evento.eventoStatusId === 3 && <Tag color="success">Confirmado</Tag>}
					{evento.eventoStatusId === 4 && <Tag color="blue">Concluído</Tag>}
				</>
			)
		},
		{
			title: "",
			render: (evento: Evento) => (
				<>
					<Button size='small' className='button-default' type='primary'>
						Detalhes
					</Button>
				</>
			)
		}
	];


	const carregaEventos = useCallback(async () => {
		setLoadingEventos(true);

		try {
			let result: Result | null = await app.ajaxApi("get", "parceiro/evento");
			setEventos(result?.dados);
		} catch (e) {
			console.log(e);
		}

		setLoadingEventos(false);
	}, [app]);



	useEffect(() => {
		carregaEventos();
	}, [carregaEventos]);




  return (
	<div className='parceiro-inicio'>
		<Row gutter={25}> 
			<Col lg={14}>
				<div className="descricao">
					<p>
						Pensando em facilitar a comunicação com nossos parceiros e a melhoria dos nossos processos, 
						desenvolvemos um formulário simples para que você possa cadastrar um novo evento e 
						acompanhar todas as etapas. 
					</p>
				</div>

				<div className="box">
					<NovoEventoForm onSuccess={carregaEventos} />

					
				</div>
			</Col>



			<Col lg={10}>
				<div className="box">
					<h2>
						<MdOutlineEventAvailable />
						Acompanhe seus eventos
					</h2>

					<Table loading={loadingEventos} columns={tabelaEvento} dataSource={eventos} />
				</div>
			</Col>
		</Row>
	</div>
  );
}

export default Inicio;