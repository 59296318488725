import { Tag } from 'antd';
import React, { useEffect, useState } from 'react';


interface EventoStatusProps {
	eventoStatusId?: number;
	eventoStatusNome?: string;
	size?: 'large' | 'small';
}


const EventoStatus: React.FC<EventoStatusProps> = ({
	eventoStatusId,
	eventoStatusNome,
	size = 'small'
}) => {

	const [color, setColor] = useState<string>();
	const [style, setStyle] = useState<any>({});

	useEffect(() => {
		if (eventoStatusId) {
			switch(eventoStatusId) {
				case 1:
					setColor('purple');
					break;

				case 2:
					setColor('warning');
					break;

				case 3:
					setColor('success');
					break;

				case 4:
					setColor('blue');
					break;

				default:
					setColor('red');
			}
		}	
		
		switch(size) {
			case 'large':
				setStyle({
					fontSize: '24px', padding: '9px 20px'
				});
				break;
		}
	}, [eventoStatusId, size]);

  return (
	<>
		{<Tag style={{ ...style, marginBottom: '3px'}} color={color}>{eventoStatusNome}</Tag>}
	</>
  );
}

export default EventoStatus;