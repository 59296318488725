import { Button, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { EventoArquivo } from '../entities/evento-arquivo';
import { Result } from '../interfaces/Result';
import { useApp } from '../providers/AppProvider';


interface EventoArquivoEditarProps {
	eventoArquivo?: EventoArquivo | null,
	onSuccess?(eventorquivo: EventoArquivo): void;
	onClose?(): void;
}


const EventoArquivoEditar: React.FC<EventoArquivoEditarProps> = ({
	eventoArquivo,
	onSuccess,
	onClose
}) => {

	const { Option } = Select; 
	const [form] = useForm();
	const app = useApp();

	const [loading, setLoading] = useState(false);


	const fechar = useCallback(() => {
		if (onClose)
			onClose();
	}, [onClose]);


	const salvar = useCallback(async (dadosForm: any) => {
		
		setLoading(true);
		let result: Result | null = await app.ajaxApi("put", `evento/arquivo/${eventoArquivo?.id}`, dadosForm);
		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);

			fechar();
		}

		setLoading(false);
	}, [app, onSuccess, fechar, eventoArquivo]);


	useEffect(() => {
		if (eventoArquivo) {
			let dadosForm = {
				...eventoArquivo,
				nome: eventoArquivo.arquivo.nome
			}
			form.setFieldsValue(dadosForm);
		}
			
	}, [eventoArquivo, form]);

  return (
	<>
		<h2>
			<FaEdit />
			Editar Arquivo
		</h2>

		<Form layout='vertical' form={form} onFinish={salvar}>
			<Form.Item label="Nome do Arquivo" name="nome">
				<Input />
			</Form.Item>

			<Form.Item label="Tipo" name="eventoArquivoTipoId">
				<Select>
					<Option value={1}>Contrato</Option>
					<Option value={2}>Nota Fiscal</Option>
					<Option value={3}>Comprovante</Option>
					<Option value={4}>Projeto</Option>
					<Option value={5}>Outros</Option>
				</Select>
			</Form.Item>

			<div className="acoes">
				<Button className='button-default' icon={<FaBan />} onClick={fechar}>Cancelar</Button>
				<Button className='button-success' icon={<FaCheck />} htmlType="submit" type="primary" loading={loading}>Salvar</Button>
			</div>
		</Form>
	</>
  );
}

export default EventoArquivoEditar;