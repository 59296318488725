/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FaCogs, FaSignOutAlt, FaUser, FaUsers } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useApp } from '../../providers/AppProvider';

import './LayoutAdmin.css';
import Logo from '../../assets/imgs/logo.png';
import { MdOutlineEventAvailable, MdTimeline } from 'react-icons/md';
import { AiOutlineDashboard } from 'react-icons/ai';

interface LayoutAdminProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const LayoutAdmin: React.FC<LayoutAdminProps> = ({ children }) => {

	const app = useApp();
	const navigate = useNavigate();



	const logout = useCallback(() => {
		app.logout();
		navigate("/");
	}, [app, navigate]);


	return (
		<div className='admin'>
			<header>
				<div className='logo'>
					Logado como: <span>{app.auth?.nome}</span>
				</div>

				<nav>					
					<Link to="/admin/configuracoes">
						<FaCogs />
						Configurações
					</Link>

					<Link to="/admin/meus-dados">
						<FaUser />
						Meus Dados
					</Link>

					<a onClick={logout}>
						<FaSignOutAlt />
						Sair
					</a>
				</nav>
			</header>

			<main>
				<nav>
					<div className="logo">
						<img src={Logo} alt="Logo TSG Tech" />
					</div>

					<Link to="/admin/painel">
						<AiOutlineDashboard />
						Dashboard
					</Link>		

					<Link to="/admin/cadastros">
						<FaUsers />
						Cadastros
					</Link>		

					<Link to="/admin/eventos">
						<MdOutlineEventAvailable />
						Eventos
					</Link>		

					<Link to="/admin/processos">
						<MdTimeline />
						Processos
					</Link>					
				</nav>

				<section>
					{children}
				</section>
			</main>

			<footer>
				SDI Digital &copy; 2022
			</footer>			
		</div>
	);
}

export default LayoutAdmin;