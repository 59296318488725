import { Button, Modal, Popconfirm, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaPlus, FaTrash, FaUsers } from 'react-icons/fa';
import { Cadastro } from '../../../../entities/cadastro';
import CadastroForm from '../../forms/Cadastro/CadastroForm';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';


const Cadastros: React.FC = () => {

	const [cadastros, setCadastros] = useState<Cadastro[]>([]);
	const [modalVisible, setModalVisible] = useState(false);
	const app = useApp();

	// ***** Estrutura da tabela
	const tableCadastros: ColumnsType<Cadastro> = [
		{
			title: "Código",
			dataIndex: "codigo"
		},
		{
			title: "Nome",
			dataIndex: "nome"
		},
		{
			title: "E-mail",
			dataIndex: "email"
		},
		{
			title: "Modo",
			render: (cadastro: Cadastro) => (
				<>
					{cadastro.cliente !== null && <Tag color="green">Cliente</Tag>}
					{cadastro.parceiro !== null && <Tag color="blue">Parceiro</Tag>}
					{cadastro.admin !== null && <Tag color="red">Usuário do Sistema</Tag>}
				</>
			)
		},
		{
			title: "",
			render: (cadastro: Cadastro) => (
				<>
					<Popconfirm  placement="topRight" title="Você confirma a EXCLUSÃO deste cadastro?" onConfirm={() => excluir(cadastro)}>
						<Button size='small' danger type='primary'>
							<FaTrash />
							Excluir
						</Button>
					</Popconfirm>
					<Button size='small' type='primary' onClick={() => app.toUrl(`/admin/cadastro/detalhes/${cadastro.id}`)}>
						<FaEdit />
						Editar
					</Button>
				</>
			)
		}
	];


	// ***** Carrega cadastros
	const carregaCadastros = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("get", "cadastro");
		if (result)
			setCadastros(result.dados);
	}, [app]);


	// ***** Abre o modal para um novo cadastro
	const novoCadastro = useCallback(() => {
		setModalVisible(true);
	}, []);


	// ***** Função executada ao realizar um cadastro com sucesso
	const cadastroSuccess = useCallback((cadastro: Cadastro) => {
		setModalVisible(false);
		carregaCadastros();
	}, [carregaCadastros]);


	// ***** Exclui um cadastro
	const excluir = useCallback(async (cadastro: Cadastro) => {
		try {

			// Exclui o cadastro
			await app.ajaxApi("DELETE", `cadastro/${cadastro.id}`);

			// Recarrega cadastros
			carregaCadastros();

		} catch (e) {
			console.log(e);
		}
	}, [app, carregaCadastros]);


	// ***** Inicializa página
	useEffect(() => {
		carregaCadastros();
	}, [carregaCadastros]);



  return (
	<>
	  	<h1>
			  <FaUsers />
			  Cadastros
		</h1>

		<div className='submenu'>
			<nav>
				<Button onClick={novoCadastro} className='button-success' type='primary' icon={<FaPlus />}>Novo Cadastro</Button>
			</nav>
		</div>

		
		<Table columns={tableCadastros} dataSource={cadastros} />

		<Modal maskClosable={false} width={700} destroyOnClose visible={modalVisible} closable footer={false} onCancel={() => setModalVisible(false)}>
			<CadastroForm onClose={() => setModalVisible(false)} onSuccess={cadastroSuccess} />
		</Modal>
	  </>
  );
}

export default Cadastros;