import { Empty } from 'antd';
import React from 'react';
import { Cadastro } from '../../../entities/cadastro';


interface ParceiroResumoProps {
	cadastroParceiro?: Cadastro
}


const ParceiroResumo: React.FC<ParceiroResumoProps> = ({
	cadastroParceiro
}) => {
  return (
	<div className="box">
		<h2>Parceiro</h2>
		
		{cadastroParceiro ? (
			<div style={{ paddingTop: '8px' }}>
				<strong style={{ fontSize: '16px' }}>{cadastroParceiro.nome}</strong>
				<p>{cadastroParceiro.email}</p>
			</div>
		) : (
			<Empty description="Nenhum parceiro vinculado" imageStyle={{ maxHeight: '40px' }} />
		)}		
	</div>
  );
}

export default ParceiroResumo;