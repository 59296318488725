import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import update from 'immutability-helper';
import { EventoContasReceber } from '../../../entities/evento-contas-receber';
import { Button, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Tag } from 'antd';
import { FaBan, FaCheck, FaEdit, FaPlus, FaTrash, FaUndo } from 'react-icons/fa';
import { ContasReceber } from '../../../entities/contas-receber';
import { useApp } from '../../../providers/AppProvider';
import { Result } from '../../../interfaces/Result';
import Modal from 'antd/lib/modal/Modal';
import FormItem from 'antd/lib/form/FormItem';
import InputFloat from '../../../masks/InputFloat';
import TextArea from 'antd/lib/input/TextArea';

const numberFormat = require('number_format-php');


interface ContasReveberEventoProps {
	eventoContasReceber?: EventoContasReceber[],
	eventoId?: string
}

const ContasReceberEvento: React.FC<ContasReveberEventoProps> = ({
	eventoContasReceber,
	eventoId,
}) => {

	const app = useApp();	
	const { Option } = Select;
	
	const [eventoContasReceberState, setEventoContasReceberState] = useState<EventoContasReceber[]>([]);
	
	const [showConfirmar, setShowConfirmar] = useState(false);
	const [contaSelecionada, setContaSelecionada] = useState<ContasReceber | null>(null);
	const [loading, setLoading] = useState(false);

	const [showEditar, setShowEditar] = useState(false);


	const tabelaReceber: ColumnsType<EventoContasReceber> = [
		{
			title: 'Data',
			render: (eventoContasReceber: EventoContasReceber) => (
				<>
					{moment(eventoContasReceber.contasReceber.data).format("DD/MM/YYYY")}
				</>
			)
		},
		{
			title: "Valor",
			render: (eventoContasReceber: EventoContasReceber) => (
				<>
					R$ {numberFormat(eventoContasReceber.contasReceber.valor, 2, ",", ".")}
				</>
			)
		},
		{
			title: "Status",
			render: (eventoContasReceber: EventoContasReceber) => (
				<>
					{eventoContasReceber.contasReceber.contasReceberStatusId === 1 && <Tag color="blue">{eventoContasReceber.contasReceber.contasReceberStatusNome}</Tag>}
					{eventoContasReceber.contasReceber.contasReceberStatusId === 2 && <Tag color="green">{eventoContasReceber.contasReceber.contasReceberStatusNome}</Tag>}
				</>
			)
		},
		{			
			render: (eventoContasReceber: EventoContasReceber) => (
				<>	
					{eventoContasReceber.contasReceber.contasReceberStatusId === 1 && (
						<>
							<Popconfirm title={<span>Você confirma a exclusão o recebimento deste valor: <strong>R$ {numberFormat(eventoContasReceber.contasReceber.valor, 2, ",", ".")}</strong></span>} onConfirm={() => excluirRecebimento(eventoContasReceber)}>
								<Button size='small' className='button-icon' danger type='primary'><FaTrash /></Button>
							</Popconfirm>
							<Button size='small' className='button-icon button-success' type='primary' onClick={() => modalConfirmarRecebimento(eventoContasReceber.contasReceber)}><FaCheck /></Button>
						</>
					)}

					{eventoContasReceber.contasReceber.contasReceberStatusId === 2 && (
						<>
							<Popconfirm title={<span>Você confirma desfazer o recebimento deste valor: <strong>R$ {numberFormat(eventoContasReceber.contasReceber.valor, 2, ",", ".")}</strong></span>} onConfirm={() => desfazerRecebimento(eventoContasReceber.contasReceber)}>
								<Button size='small' className='button-icon button-warning' type='primary'><FaUndo /></Button>
							</Popconfirm>
						</>
					)}
				</>
			)
		}
	];


	const modalConfirmarRecebimento = useCallback(async(contasReceber: ContasReceber) => {
		setShowConfirmar(true);
		setContaSelecionada(contasReceber);
	}, []);

	const fecharConfirmarRecebimento = useCallback(() => {
		setShowConfirmar(false);
		setContaSelecionada(null);
	}, []);


	const confirmarRecebimento = useCallback(async(formData: any) => {

		setLoading(true);
		
		let result: Result | null = await app.ajaxApi("PUT", `financeiro/contas-receber/${contaSelecionada?.id}/confirmar-recebimento`, formData);
		if (result?.code === 200) {
			let itemAtualizado;
			let dadosAtualizados: any = null;

			eventoContasReceberState.forEach((conta: EventoContasReceber, index: number) => {
				if (conta.contasReceber.id === contaSelecionada?.id) {
					itemAtualizado = index;
					dadosAtualizados = conta;
				}
			});
			
			if (itemAtualizado !== undefined && dadosAtualizados !== null) {			
				dadosAtualizados.contasReceber = result.dados;
				setEventoContasReceberState(
					update(eventoContasReceberState, {
					$splice: [
							[itemAtualizado, 1],
							[itemAtualizado, 0, dadosAtualizados],
						],
					}),
				);
			}
		}

		setLoading(false);
		fecharConfirmarRecebimento();
		
	}, [eventoContasReceberState, app, contaSelecionada, fecharConfirmarRecebimento]);



	const desfazerRecebimento = useCallback(async(contasReceber: ContasReceber) => {
		
		let result: Result | null = await app.ajaxApi("PUT", `financeiro/contas-receber/${contasReceber?.id}/desfazer-recebimento`);
		if (result?.code === 200) {
			let itemAtualizado;
			let dadosAtualizados: any = null;

			eventoContasReceberState.forEach((conta: EventoContasReceber, index: number) => {
				if (conta.contasReceber.id === contasReceber?.id) {
					itemAtualizado = index;
					dadosAtualizados = conta;
				}
			});
			
			if (itemAtualizado !== undefined && dadosAtualizados !== null) {			
				dadosAtualizados.contasReceber = result.dados;
				setEventoContasReceberState(
					update(eventoContasReceberState, {
					$splice: [
							[itemAtualizado, 1],
							[itemAtualizado, 0, dadosAtualizados],
						],
					}),
				);
			}
		}
		
	}, [eventoContasReceberState, app]);



	const excluirRecebimento = useCallback(async(eventoContasReceber: EventoContasReceber) => {
		
		let result: Result | null = await app.ajaxApi("DELETE", `financeiro/contas-receber/${eventoContasReceber.contasReceber.id}/evento/${eventoContasReceber.eventoId}`);
		if (result?.code === 200) {
			let itemExcluido;			

			eventoContasReceberState.forEach((conta: EventoContasReceber, index: number) => {
				if (conta.contasReceber.id === eventoContasReceber.contasReceberId) {
					itemExcluido = index;					
				}
			});
			
			if (itemExcluido !== undefined) {	
				setEventoContasReceberState(
					update(eventoContasReceberState, {
						$splice: [
							[itemExcluido, 1], [itemExcluido, 0]
						]
					}),
				);
			}
		}
		
	}, [eventoContasReceberState, app]);



	const novaContaReceber = useCallback(() => {
		setShowEditar(true);
		setContaSelecionada(null);
	}, []);

	const fecharEditar = useCallback(() => {
		setShowEditar(false);
		setContaSelecionada(null);
	}, []);

	const salvar = useCallback(async (formData: any) => {
		setLoading(true);

		let dadosForm = {
			...formData,
			eventoId: eventoId
		}		

		let result: Result | null = await app.ajaxApi("POST", `financeiro/contas-receber/evento`, dadosForm);
		if (result?.code === 200) {

			let novoItem: EventoContasReceber = {
				eventoId: eventoId ? eventoId : '',
				contasReceberId: result.dados.id,
				contasReceber: result.dados,
				key: result.dados.id
			};

			setEventoContasReceberState(
				update(eventoContasReceberState, {
					$push: [novoItem]
				})
			);
		}

		setLoading(false);
		fecharEditar();
	}, [eventoId, app, eventoContasReceberState, fecharEditar]);



	useEffect(() => {
		if (eventoContasReceber)
			setEventoContasReceberState(eventoContasReceber);
	}, [eventoContasReceber])



	return (
		<>
			<div className="box">
				<h2>Contas a Receber</h2>

				<div className="submenu">
					<Button type='primary' className='button-success' onClick={novaContaReceber}>
						<FaPlus />
						Novo Recebimento						
					</Button>
				</div>

				<Table columns={tabelaReceber} dataSource={eventoContasReceberState} />
			</div>

			<Modal visible={showConfirmar} footer={false} destroyOnClose maskClosable={false} closable onCancel={fecharConfirmarRecebimento}>
				<h2>
					<FaCheck />
					Confirmar Recebimento
				</h2>
				<p>
					Você confirma o recebimento do valor: <strong>R$ {numberFormat(contaSelecionada?.valor, 2, ",", ".")}?</strong>
				</p>

				<Form layout='vertical' onFinish={confirmarRecebimento}>
					<FormItem label="Data de Recebimento" name="dataRecebimento" rules={[
						{ required: true, message: "Informe a data de recebimento" }
					]}>
						<DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
					</FormItem>

					<div className="acoes">
						<Button className='button-default' type="primary" icon={<FaBan />} onClick={fecharConfirmarRecebimento}>Cancelar</Button>
						<Button htmlType='submit' className='button-success' type="primary" icon={<FaCheck />} loading={loading}>Confirmar</Button>
					</div>
				</Form>
			</Modal>


			<Modal visible={showEditar} closable maskClosable={false} destroyOnClose footer={false} onCancel={fecharEditar}>
				<h2>
					<FaEdit />
					Editar Conta a Receber
				</h2>

				<Form layout='vertical' onFinish={salvar}>

					<Row gutter={25}>
						<Col md={12}>
							<FormItem label="Data" name="data" rules={[
								{ required: true, message: "Informa a data" }
							]}>
								<DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
							</FormItem>
						</Col>

						<Col md={12}>
							<FormItem label="Status" name="contasReceberStatusId" rules={[
								{ required: true, message: "Informe o status" }
							]}>
								<Select style={{ width: '100%' }}>
									<Option value={1}>Aguardando Pagamento</Option>
									<Option value={2}>Pago</Option>
								</Select>
							</FormItem>
						</Col>
					</Row>
					

					<FormItem label="Descrição" name="descricao">
						<Input />
					</FormItem>

					<FormItem label="Valor" name="valor" rules={[
						{ required: true, message: "Informe o valor" }
					]}>
						<InputFloat />
					</FormItem>

					<FormItem label="Observações" name="obs">
						<TextArea rows={5} />
					</FormItem>

					<div className="acoes">
						<Button type='primary' className='button-default' onClick={fecharEditar} icon={<FaBan />}>Cancelar</Button>
						<Button type='primary' className='button-success' htmlType='submit' icon={<FaCheck />} loading={loading}>Salvar</Button>
					</div>
				</Form>
			</Modal>
		</>
	);
}

export default ContasReceberEvento;