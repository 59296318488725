import { Alert, Button, Col, DatePicker, Form, Input, notification, Result, Row, Spin, Steps, UploadProps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useState } from 'react';
import { FaArrowLeft, FaCheck, FaCloudUploadAlt, FaUser } from 'react-icons/fa';
import { MdOutlineAttachEmail, MdOutlineEventAvailable } from 'react-icons/md';
import { VscNewFile } from 'react-icons/vsc';
import moment from 'moment';

import InputTelefone from '../../../../masks/InputTelefone';

import './NovoEventoForm.css';
import { Result as ResultApp } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import { Evento } from '../../../../entities/evento';
import Dragger from 'antd/lib/upload/Dragger';
import FormProps from '../../../../interfaces/FormProps';
import { useForm } from 'antd/lib/form/Form';

const { Step } = Steps;

const NovoEventoForm: React.FC<FormProps> = ({
	onSuccess,
	onClose,
	data
}) => {

	const app = useApp();
	const [formCliente] = useForm();
	const [formEvento] = useForm();

	const [etapa, setEtapa] = useState(0);
	const [loading, setLoading] = useState(false);
	const [loadingUpload, setLoadingUpload] = useState(false);
	const [dadosCliente, setDadosCliente] = useState<any | null>(null);
	const [evento, setEvento] = useState<Evento | null>(null);	


	// ***** Configurações do upload PRIVADO
	const propsPrivado: UploadProps = {
		name: 'arquivo',		
		multiple: true,				
		action: `${process.env.REACT_APP_URL_API}/arquivo/upload`,
		headers: { 
			"Tenant": process.env.REACT_APP_TENANT ? process.env.REACT_APP_TENANT : ``,
			"Authorization": `Bearer ${sessionStorage.getItem("token")}`
		},
		data: { privado: true },
		showUploadList: false
	  };


	const submitCliente = useCallback((formData: any) => {
		setDadosCliente(formData);
		setEtapa(1);
	}, []);


	const submitEvento = useCallback(async (formData: any) => {		
		setLoading(true);
		let dataInicio = moment(formData.dataInicio).format("YYYY-MM-DD");

		try {
			let dados = {
				...dadosCliente,
				...formData,
				dataInicio
			};

			let result: ResultApp | null = await app.ajaxApi("POST", "parceiro/evento", dados);
			if (result?.code === 200) {
				setEvento(result.dados);
				setEtapa(2);				
			}

		} catch (e) {
			console.log(e);
		}

		setLoading(false);
	}, [dadosCliente, app]);


	const cadastraArquivo = useCallback(async (info:any) => {
		if (info.file.status === "done") {		
			let arquivoId = info.file.response.dados.id;
			
			let dadosArquivo = {
				eventoId: evento?.id,
				eventoArquivoTipoId: 1,
				arquivoId
			};

			try {
				await app.ajaxApi("POST", "evento/arquivo", dadosArquivo);
				await app.ajaxApi("PUT", `parceiro/evento/status/${evento?.id}`, { eventoStatusId: 3 });
				
				notification.success({
					message: "Evento cadastrado com sucesso",
					duration: 10,
					placement: "bottomRight"
				});
				setEtapa(3);

				if (onSuccess)
					onSuccess(evento);

			} catch (e) {
				console.log(e);
			}

			setLoadingUpload(false);
		}
	}, [app, evento, onSuccess]);
	


	const novoEvento = useCallback(() => {
		setEtapa(0);
		setDadosCliente(null);
		setEvento(null);		
		formCliente.resetFields();
		formEvento.resetFields();
	}, [formCliente, formEvento]);



	const pularContrato = useCallback(() => {
		if (onSuccess)
			onSuccess(evento);

		notification.success({
			message: "Evento cadastrado com sucesso",
			description: "Não esqueça de anexar o contrato para confirmar o evento",
			duration: 10,
			placement: "bottomRight"
		});
		setEtapa(3);		
	}, [onSuccess, evento]);



  return (
	<>
		<h2>
			<VscNewFile />
			Cadastrar Evento
		</h2>

		<Steps type="navigation" current={etapa}>
			<Step title="Cliente" description="Dados do cliente" />
			<Step title="Evento" description="Dados do evento" />
			<Step title="Contrato" description="Anexe o contrato" />
			<Step title="Concluído" />
		</Steps>


		<div style={{ display: etapa === 0 ? `block` : `none` }}>
			<div className="item-etapa">
				<h3>
					<FaUser />
					Dados do Cliente
				</h3>

				<Form form={formCliente} layout='vertical' onFinish={submitCliente}>
					<Form.Item label="Nome Completo" name="nome" rules={[
						{ required: true, message: "Informe o nome" }
					]}>
						<Input />
					</Form.Item>

					<Row gutter={25}>
						

						<Col md={12}>
							<Form.Item label="E-mail" name="email" rules={[
								{ required: true, message: "Informe o e-mail" }
							]}>
								<Input />
							</Form.Item>
						</Col>

						<Col md={12}>
							<Form.Item label="Celular" name="celular" rules={[
								{ required: true, message: "Informe o celular" }
							]}>
								<InputTelefone />
							</Form.Item>
						</Col>
					</Row>

					<div className="acoes" style={{ marginTop: '40px' }}>
						<Button htmlType='submit' type='primary' size='large' icon={<FaCheck />}>Ir para dados do evento</Button>
					</div>
				</Form>
			</div>
		</div>

		<div style={{ display: etapa === 1 ? `block` : `none` }}>
			<div className="item-etapa">
				<h3>
					<MdOutlineEventAvailable />
					Dados do Evento
				</h3>

				<Form form={formEvento} layout='vertical' onFinish={submitEvento}>

					<Row gutter={25}>
						<Col md={12}>
							<Form.Item label="Data do Evento" name="dataInicio" rules={[
								{ required: true, message: "Informe a data do evento" }
							]}>								
								<DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />								
							</Form.Item>

							<Form.Item label="Nome do Evento" name="nomeEvento">
								<Input />
							</Form.Item>

							<Form.Item label="Nome do Produtor" name="produtor">
								<Input />
							</Form.Item>
						</Col>

						<Col md={12}>
							<Form.Item label="Observações" name="observacao">
								<TextArea rows={8} />
							</Form.Item>
						</Col>
						
					</Row>

					<div className="acoes" style={{ marginTop: '40px' }}>
						<Button type='primary' className='button-default' icon={<FaArrowLeft />} size='large' onClick={() => setEtapa(0)}>Voltar</Button>
						<Button htmlType='submit' loading={loading} type='primary' size='large' icon={<FaCheck />} >Ir para contrato</Button>
					</div>
				</Form>
			</div>
		</div>

		<div style={{ display: etapa === 2 ? `block` : `none` }}>
			<div className="item-etapa">
				<h3>
					<MdOutlineAttachEmail />
					Anexar Contrato
				</h3>
				
				<Dragger {...propsPrivado} height={100} beforeUpload={() => setLoadingUpload(true)} onChange={cadastraArquivo} style={{ backgroundColor: "#daebff", marginTop: "15px" }}>
					<div className='upload'>
						{loadingUpload ? <Spin spinning size='large' /> : <FaCloudUploadAlt size={40} color="#4794f2" />}
						<h2 style={{ color: "#4794f2" }}>Enviar Contrato</h2>
					</div>
					<p>Clique aqui ou arraste o arquivo para esta área para fazer upload</p>						
				</Dragger>

				<Alert style={{ marginTop: '5px' }} type='warning' message="Informação" showIcon description="O envio do contrato não é obrigatório nesse cadastro inicial, porém deverá ser enviado na sequencia para que assim possa ser confirmado o evento" />
		
				<div style={{ marginTop: '40px', textAlign: 'right' }}>					
					<Button type='primary' size='large' icon={<FaCheck />} onClick={pularContrato}>Pular Etapa</Button>
				</div>
			</div>
		</div>

		<div style={{ display: etapa === 3 ? `block` : `none` }}>
			<div className="item-etapa">
				
			<Result
				status="success"
				title="Evento cadastrado com sucesso!"
				subTitle={(
					<>
						<p style={{ margin: 0 }}>Cliente: <strong>{dadosCliente?.nome}</strong></p>
						<p>Data do Evento: <strong>{moment(evento?.dataInicio).format("DD/MM/YYYY")}</strong></p>
					</>
				)}
				extra={[
					<Button type="primary" key="console" onClick={novoEvento}>
						Cadastrar Novo Evento
					</Button>
				]}
			/>
				
			</div>
		</div>
		
	</>
  );
}

export default NovoEventoForm;