import { Button, Col, DatePicker, Form, notification, Row, Select } from 'antd';
import Input from 'antd/lib/input/Input';
import React, { useCallback, useEffect, useState } from 'react';
import { Evento } from '../../../entities/evento';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { FaCheck } from 'react-icons/fa';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import InputFloat from '../../../masks/InputFloat';


interface EventoEditarProps {
	evento?: Evento,
	editable?: boolean,
	onSuccess?(evento: Evento): void
}


const numberFormat = require("number_format-php");


const EventoEditar: React.FC<EventoEditarProps> = ({
	evento,
	editable = false,
	onSuccess
}) => {

	const { Option } = Select;
	const [form] = useForm();
	const [loading, setLoading] = useState(false);
	const app = useApp();



	const salvar = useCallback(async (formData: any) => {
		if (!editable)
			return;

		setLoading(true);
		
		let result: Result | null = await app.ajaxApi("put", `admin/evento/${evento?.id}`, formData);
		
		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);
			
			notification.success({
				message: "Dados do evento atualizados",
				duration: 5,
				placement: 'bottomRight'
			});
		}
		
		setLoading(false);

	}, [app, evento, editable, onSuccess]);



	useEffect(() => {
		if (evento) {
			let dadosForm = {
				...evento,
				dataInicio: moment(evento.dataInicio),
				valor: numberFormat(evento.valor, 2, ',', '.'),
				created: moment(evento.created).format("DD/MM/YYYY -- HH:mm:ss"),
				modified: moment(evento.modified).format("DD/MM/YYYY -- HH:mm:ss"),
			}
			form.setFieldsValue(dadosForm);
		}
			
	}, [evento, form]);


	return (
		<div className="box">
			<h2>Dados do Evento</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				<Row gutter={25}>
					<Col md={8}>
						<Form.Item label="ID" name="id">
							<Input disabled />
						</Form.Item>
					</Col>				

					<Col md={8}>
						<Form.Item label="Código" name="codigo">
							<Input readOnly />
						</Form.Item>
					</Col>

					<Col md={8}>
						<Form.Item label="Status" name="eventoStatusId">
							<Select>
								<Option value={1}>Novo</Option>
								<Option value={2}>Pendente</Option>
								<Option value={3}>Confirmado</Option>
								<Option value={4}>Concluído</Option>
								<Option value={5}>Cancelado</Option>
							</Select>
						</Form.Item>
					</Col>

					<Col md={8}>
						<Form.Item label="Nome do evento" name="nome">
							<Input />
						</Form.Item>
					</Col>				

					<Col md={8}>
						<Form.Item label="Data" name="dataInicio">
							<DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />							
						</Form.Item>
					</Col>				

					<Col md={8}>
						<Form.Item label="Produtor" name="produtor">
							<Input />
						</Form.Item>
					</Col>				

					<Col md={8}>
						<Form.Item label="Local" name="local">
							<Input />
						</Form.Item>
					</Col>				

					<Col md={8}>
						<Form.Item label="Tipo" name="tipo">
							<Input />
						</Form.Item>
					</Col>				

					<Col md={8}>
						<Form.Item label="Valor" name="valor">
							<InputFloat />
						</Form.Item>
					</Col>	

					<Col md={14}>
						<Form.Item label="Observações" name="observacao">
							<TextArea rows={5} />
						</Form.Item>
					</Col>	

					<Col md={10}>
						<Form.Item label="Data de Cadastro" name="created">
							<Input disabled />
						</Form.Item>

						<Form.Item label="Última Alteração" name="modified">
							<Input disabled />
						</Form.Item>
					</Col>				
				</Row>

				{editable && (
					<div className="acoes">
						<Button htmlType='submit' type='primary' loading={loading} className='button-success' icon={<FaCheck />}>							
							Salvar
						</Button>
					</div>
				)}				
			</Form>
			
		</div>
	);
}

export default EventoEditar;