import { Button, Col, Progress, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'react-google-charts';
import { MdStorage } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Result } from '../interfaces/Result';
import { useApp } from '../providers/AppProvider';


interface ArquivoInfoProps {
	showGerenciarArquivos?: boolean
}


const ArquivoInfo: React.FC<ArquivoInfoProps> = ({
	showGerenciarArquivos = true
}) => {

	const optionsChart = {
		colors: ['#95ce5a', "#e26d64"]
	}

	const [info, setInfo] = useState<any>();
	const [pieData, setPieData] = useState<any>();

	const app = useApp();
	const navigate = useNavigate();

	const carregaDados = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("get", "arquivo/status");
		setInfo(result?.dados);

		let data = [
			["Arquivos", "Quantidade"],
			["Públicos", result?.dados.publicos],
			["Privados", result?.dados.privados],
		];
		setPieData(data);
		
	}, [app]);

	const converteBytes = useMemo(() => {
		if (info) {
			let disponivel = 1 * 1024 * 1024 * 1024;
			let porcentagem = (info.size / disponivel) * 100;
			porcentagem = parseFloat(porcentagem.toFixed(1));
			return porcentagem;
		}
		
		return 0;
	}, [info]);

	useEffect(() => {
		carregaDados();
	}, [carregaDados]);


  return (
	  <div className='widget'>
	  	<h2>
			  <MdStorage />
			  Armazenamento
		</h2>
		
		<Row>
			<Col md={12}>
				<div className='infoItem'>
					<p>Espaço Disponível:</p>
					<strong>1GB</strong>
				</div>

				<div className='infoItem'>
					<p>Espaço Utilizado:</p>
					{info ? <strong>{info?.armazenamento} / {(info?.privados + info?.publicos)} arquivo(s)</strong> : <i>Calculando...</i>}
					
				</div>

				<div style={{ width: '95%', marginBottom: '25px' }}>
					<Progress type='line' percent={converteBytes} width={250} showInfo />
				</div>				

				{showGerenciarArquivos && <Button size='small' onClick={() => navigate("/admin/arquivos")} type='primary' style={{ marginRight: '5px' }}>
					<MdStorage />
					Gerenciar Arquivos
				</Button>}

				<Button onClick={carregaDados} type='primary' size='small' className='button-default'>
					<HiOutlineRefresh />
					Atualizar
				</Button>
			</Col>

			<Col md={12}>
				{pieData && <Chart chartType='PieChart' data={pieData} options={optionsChart} />}
			</Col>

		</Row>
		
	  </div>
  );
}

export default ArquivoInfo;