import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { MdOutlineEventAvailable } from 'react-icons/md';
import moment from 'moment';

import { Evento } from '../../../../entities/evento';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import { Button, Modal } from 'antd';
import { FaPlus, FaSearch } from 'react-icons/fa';
import EventoStatus from '../../../../components/EventoStatus';
import EventoNovo from '../../components/EventoNovo';


const numberFormat = require('number_format-php');

const Eventos: React.FC = () => {

	const [eventos, setEventos] = useState<Evento[]>([]);
	const [showEventoNovo, setShowEventoNovo] = useState(false);

	const app = useApp();
	


	const tabelaEventos: ColumnsType<Evento> = [
		{
			title: "Data do Evento",
			render: (evento: Evento) => (
				<strong>
					{moment(evento.dataInicio).format("DD/MM/YYYY")}
				</strong>
			)
		},
		{
			title: "Cliente",
			render: (evento: Evento) => (
				<>
					<span>{evento.cadastroCliente.nome}</span>
				</>
			)
		},
		{
			title: "Local",
			dataIndex: "local"
		},
		{
			title: "Status",
			render: (evento: Evento) => (
				<EventoStatus eventoStatusId={evento.eventoStatusId} eventoStatusNome={evento.eventoStatusNome} />
			)
		},
		{
			title: "Valor",
			render: (evento: Evento) => (
				<>
					{evento.valor === 0 ? <strong style={{ color: '#990000' }}>Não definido</strong> : <strong>R$ {numberFormat(evento.valor, 2, ',', '.')}</strong> }
				</>
			)
		},
		{			
			render: (evento: Evento) => (
				<>
					<Button type='primary' size='small' className='button-default' onClick={() => app.toUrl(`/admin/eventos/detalhes/${evento.id}`)}>
						<FaSearch />
						Detalhes
					</Button>
				</>
			) 
		}
	]


	
	const carregaEventos = useCallback(async () => {
		try {
			let result: Result | null = await app.ajaxApi("get", `admin/evento`);
			setEventos(result?.dados);
		} catch (e) {
			console.log(e);
		}
	}, [app]);


	const novoEvento = useCallback(() => {
		setShowEventoNovo(true);
	}, []);


	const atualizaEventos = useCallback(() => {
		carregaEventos();
	}, [carregaEventos]);

	
	useEffect(() => {
		carregaEventos();
	}, [carregaEventos]);



	return (
		<>
			<h1>
				<MdOutlineEventAvailable />
				Eventos
		  	</h1>

			<div className="submenu">
				<Button type='primary' className='button-success' onClick={novoEvento}>
					<FaPlus />
					Novo Evento
				</Button>				
			</div>

			<Table columns={tabelaEventos} dataSource={eventos} />


			<Modal closable maskClosable={false} width={800} footer={false} visible={showEventoNovo} destroyOnClose onCancel={() => setShowEventoNovo(false)}>
				<EventoNovo onSuccess={atualizaEventos} onClose={() => setShowEventoNovo(false)} />
			</Modal>
		</>
	);
}

export default Eventos;