import { Button, Col, Form, Input, InputNumber, notification, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { Processo } from '../../../entities/processo';
import { ProcessoEtapa } from '../../../entities/processo-etapa';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';


interface ProcessoEtapaEditarProps {
	processoEtapa?: ProcessoEtapa | null,
	processoId?: string,
	onSucess?(processoEtapa: ProcessoEtapa): void,
	onClose?(): void
}


const ProcessoEtapaEditar: React.FC<ProcessoEtapaEditarProps> = ({
	processoEtapa,
	processoId,
	onSucess,
	onClose
}) => {

	const [form] = useForm();
	const app = useApp();

	const { Option } = Select;

	const [processos, setProcessos] = useState<Processo[]>([]);
	const [loading, setLoading] = useState(false);



	const carregaProcessos = useCallback(async () => {		
		let result: Result | null = await app.ajaxApi("get", "processo");
		let itemRemover;
		
		if (result?.code === 200) {				
			let dados: Processo[] = result.dados;				
			dados.forEach((item: Processo, index: number) => {
				if (item.id === processoId)
					itemRemover = index;
			});

			if (itemRemover !== undefined)
				dados.splice(itemRemover, 1);

			setProcessos(dados);
		}
	}, [app, processoId]);



	const salvar = useCallback(async (formData: any) => {
		setLoading(true);

		if (processoEtapa?.id) {
			// ***** ALTERAR
			let result: Result | null = await app.ajaxApi("put", `processo/etapa/${processoEtapa.id}`, formData);
			if (result?.code === 200) {
				if (onSucess)
					onSucess(result.dados);

				if (onClose)
					onClose();

				notification.success({
					message: "Etapa atualizada",
					duration: 5,
					placement: 'bottomRight'
				});
			}		
		} else {
			// ***** INSERIR
			formData["processoId"] = processoId;
			let result: Result | null = await app.ajaxApi("post", `processo/etapa`, formData);
			if (result?.code === 200) {
				if (onSucess)
					onSucess(result.dados);

				if (onClose)
					onClose();

				notification.success({
					message: "Etapa cadastrada",
					duration: 5,
					placement: 'bottomRight'
				});
			}		
		}

		setLoading(false);
	}, [processoEtapa, processoId, app, onSucess, onClose]);



	const selecionaSubprocesso = useCallback((idProcesso: string) => {
		if (idProcesso) {
			let sub: Processo | undefined = processos.find((item: Processo) => {
				if (item.id === idProcesso)
					return true;

				return false;
			});

			if (sub !== undefined) {
				form.setFieldsValue({
					nome: sub.nome + " (PROCESSO)",
					descricao: sub.descricao
				});
			}
		} else {
			form.resetFields();
		}
	}, [form, processos]);



	useEffect(() => {
		if (processoEtapa) 
			form.setFieldsValue(processoEtapa);

		carregaProcessos();
	}, [processoEtapa, form, carregaProcessos])



	return (
		<>
			<h2>
				<FaEdit />
				Editar Etapa do Processo
			</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				<FormItem label="Vincular Subprocesso" name="subProcessoId">
					<Select style={{ width: '100%' }} allowClear onChange={(value) => selecionaSubprocesso(value)}>
						{processos.map((processo: Processo) => (
							<Option key={processo.key} value={processo.id}>{processo.nome}</Option>
						))}
					</Select>
				</FormItem>

				<FormItem label="Nome" name="nome" rules={[
					{ required: true, message: "Informe o nome da etapa" }
				]}>
					<Input />
				</FormItem>

				<FormItem label="Descrição" name="descricao">
					<TextArea />
				</FormItem>

				<Row gutter={25}>
					<Col md={12}>
						<FormItem label="Prazo (em dias)" name="prazo">
							<InputNumber style={{ width: '100%' }} />
						</FormItem>
					</Col>

					<Col md={12}>
						<FormItem label="Ordem" name="ordem">
							<Input disabled />
						</FormItem>
					</Col>
				</Row>

				<div className="acoes">
					{onClose && (
						<Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>
					)}
					<Button type='primary' className='button-success' icon={<FaCheck />} htmlType="submit" loading={loading}>Salvar</Button>
				</div>
			</Form>
		</>
	);
}

export default ProcessoEtapaEditar;