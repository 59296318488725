import { Button, Checkbox, Col, Form, Input, Row, Select, Typography } from 'antd';
import Password from 'antd/lib/input/Password';

import React, { useCallback, useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa';
import { MdOutlineCheckCircleOutline, MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import FormProps from '../../../../interfaces/FormProps';
import { Result } from '../../../../interfaces/Result';
import InputCnpj from '../../../../masks/InputCnpj';
import InputCpf from '../../../../masks/InputCpf';
import InputTelefone from '../../../../masks/InputTelefone';
import { useApp } from '../../../../providers/AppProvider';


import './CadastroForm.css';

const CadastroForm: React.FC<FormProps> = ({	
	onSuccess,
	onClose,
	data
}) => {
	const { Option } = Select;
	const { Text } = Typography;

	const [tipo, setTipo] = useState<null | number>(null);
	const [login, setLogin] = useState(false);
	const [cliente, setCliente] = useState(false);
	const [parceiro, setParceiro] = useState(false);
	const [admin, setAdmin] = useState(false);

	const [loading, setLoading] = useState(false);
	const [errModo, setErrModo] = useState<string | null>(null);


	const app = useApp();



	const salvar = useCallback(async (data: any) => {
		// Limpa erro do modo de cadastro
		setErrModo(null);

		// Verifica se algum modo de cadastro foi selecionado
		if (!cliente && !parceiro && !admin) {
			setErrModo("Selecione o modo de cadastro");
			return;
		}
		
		// Inicia loading
		setLoading(true);

		try {
			// Cria o body da requisição
			let dados = {
				...data,
				cliente,
				parceiro,
				admin
			}
			
			// Cadastra usuario
			let result: Result | null = await app.ajaxApi("POST", "cadastro", dados);
			
			// Chama a função SUCCESS passando o resultado
			if (onSuccess && result?.code === 200)
				onSuccess(result.dados);
			
		} catch (e) {			
			console.log(e);
		}

		setLoading(false);
		
	}, [cliente, parceiro, admin, app, onSuccess]);


  return (
	<>
		<h2>Novo Cadastro</h2>

		<div className="tipo-cadastro">
			<div className={`item ${cliente && `ativo`}`} onClick={() => setCliente(!cliente)}>
				{cliente ? <MdOutlineCheckCircleOutline /> : <MdOutlineRadioButtonUnchecked />}
				Cliente
			</div>

			<div className={`item ${parceiro && `ativo`}`} onClick={() => setParceiro(!parceiro)}>
				{parceiro ? <MdOutlineCheckCircleOutline /> : <MdOutlineRadioButtonUnchecked />}
				Parceiro
			</div>

			<div className={`item ${admin && `ativo`}`} onClick={() => setAdmin(!admin)}>
				{admin ? <MdOutlineCheckCircleOutline /> : <MdOutlineRadioButtonUnchecked />}
				Usuário do Sistema
			</div>
		</div>
		{errModo && <Text type='danger'>{errModo}</Text>}

		<Form layout='vertical' onFinish={salvar} style={{ marginTop: '20px' }}>

			<Row gutter={25}>
				<Col md={12}>
					<Form.Item label="Tipo" name="tipo" rules={[
							{ required: true, message: "Selecione o tipo" }
						]}>
						<Select onChange={(value) => setTipo(value)} placeholder="Selecione o tipo" allowClear>							
							<Option value={1}>Pessoa Física</Option>	
							<Option value={2}>Pessoa Jurídica</Option>	
						</Select>
					</Form.Item>	
				</Col>

				<Col md={12}>
					<Form.Item label="Nome" name="nome" rules={[
						{ required: true, message: "Informe o nome" }
					]}>
						<Input />
					</Form.Item>	
				</Col>
			</Row>			
			

			<Row gutter={25}>
				<Col md={12}>
					<Form.Item label="E-mail" name="email" rules={[
						{ required: true, message: "Informe o e-mail" }
					]}>
						<Input />
					</Form.Item>		
				</Col>

				<Col md={12}>
					{tipo === 1 ? (
						<Form.Item label="CPF" name="cpf">
							<InputCpf />
						</Form.Item>
					) : (
						<Form.Item label="CNPJ" name="cnpj">
							<InputCnpj />
						</Form.Item>
					)}
					

							
				</Col>
			</Row>	


			<Row gutter={25}>
				<Col md={12}>
					<Form.Item label="Celular" name="celular" rules={[
						{ required: true, message: "Informe o celular" }
					]}>
						<InputTelefone />
					</Form.Item>		
				</Col>

				<Col md={12}>
					<Form.Item label="Telefone" name="telefone">
						<InputTelefone />
					</Form.Item>		
				</Col>
			</Row>	

			<Form.Item name="login">				
				<Checkbox onChange={() => setLogin(!login)} checked={login}>Ativar Login</Checkbox>
			</Form.Item>		

			{login && (
				<Row gutter={25}>
					<Col md={12}>
						<Form.Item label="Senha" name="senha" rules={[
							{ required: login, message: "Informe a senha" }
						]}>
							<Password />
						</Form.Item>		
					</Col>

					<Col md={12}>
						<Form.Item label="Confirme a Senha" name="senhaConfirm" rules={[
							{ required: login, message: "Confirme a senha" }
						]}>
							<Password />
						</Form.Item>		
					</Col>
				</Row>	
			)}

			<div className="acoes">
				{onClose && (
					<Button  className='button-default' onClick={onClose} type="primary" icon={<FaBan />}>Cancelar</Button>
				)}
				<Button loading={loading} htmlType='submit' className='button-success' type="primary" icon={<FaCheck />}>Salvar</Button>
			</div>

		</Form>
	</>
  );
}

export default CadastroForm;