import { Button, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Tag } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaEdit, FaPlus, FaTrash, FaUndo } from 'react-icons/fa';
import update from 'immutability-helper';
import { ContasPagar } from '../../../entities/contas-pagar';
import { EventoContasPagar } from '../../../entities/evento-contas-pagar';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import InputFloat from '../../../masks/InputFloat';
import TextArea from 'antd/lib/input/TextArea';


const NumberFormat = require('number_format-php');

interface ContasPagarEventoProps {
	eventoContasPagar?: EventoContasPagar[],
	eventoId?: string
}


const ContasPagarEvento: React.FC<ContasPagarEventoProps> = ({
	eventoContasPagar,
	eventoId
}) => {

	const { Option} = Select;

	const app = useApp();
	const [eventoContasPagarState, setEventoContasPagarState] = useState<EventoContasPagar[]>([]);

	const [showConfirmarPagamento, setShowConfirmarPagamento] = useState(false);
	const [loading, setLoading] = useState(false);
	const [contaSelecionada, setContaSelecionada] = useState<ContasPagar | null>(null);

	const [showEditar, setShowEditar] = useState(false);


	const tabelaPagar: ColumnsType<EventoContasPagar> = [
		{
			title: "Descrição",
			render: (eventoContasPagar: EventoContasPagar) => (
				<span>{eventoContasPagar.contasPagar.descricao}</span>
			)
		},
		{
			title: "Valor",
			render: (eventoContasPagar: EventoContasPagar) => (
				<strong>{NumberFormat(eventoContasPagar.contasPagar.valor, 2, ",", ".")}</strong>
			)
		},
		{
			title: "Status",
			render: (eventoContasPagar: EventoContasPagar) => (
				<>
					{eventoContasPagar.contasPagar.contasPagarStatusId === 1 && <Tag color="red">{eventoContasPagar.contasPagar.contasPagarStatusNome}</Tag>}
					{eventoContasPagar.contasPagar.contasPagarStatusId === 2 && <Tag color="green">{eventoContasPagar.contasPagar.contasPagarStatusNome}</Tag>}
				</>
			)
		},
		{			
			render: (eventoContasPagar: EventoContasPagar) => (
				<>
					{eventoContasPagar.contasPagar.contasPagarStatusId === 1 && (
						<>
							<Popconfirm title={<span>Você confirma a exclusão este pagamento?<br /><strong>{eventoContasPagar.contasPagar.descricao}</strong></span>} onConfirm={() => excluirPagamento(eventoContasPagar.contasPagar)}>
								<Button size='small' type='primary' className='button-icon' danger icon={<FaTrash />} />
							</Popconfirm>
							<Button size='small' type='primary' className='button-success button-icon' icon={<FaCheck />} onClick={() => modalConfirmarPagamento(eventoContasPagar.contasPagar)} />
						</>
					)}

					{eventoContasPagar.contasPagar.contasPagarStatusId === 2 && (
						<>
							<Popconfirm title={<span>Você confirma desfazer este pagamento?<br /><strong>{eventoContasPagar.contasPagar.descricao}</strong></span>} onConfirm={() => desfazerPagamento(eventoContasPagar.contasPagar)}>
								<Button size='small' type='primary' className='button-warning button-icon' icon={<FaUndo />} />
							</Popconfirm>
						</>
					)}
				</>
			)
		},
	];




	const modalConfirmarPagamento = useCallback((contasPagar: ContasPagar) => {
		setShowConfirmarPagamento(true);
		setContaSelecionada(contasPagar);
	}, []);

	const fecharModalConfirmarPagamento = useCallback(() => {
		setShowConfirmarPagamento(false);
		setContaSelecionada(null);
	}, []);

	const confirmarPagamento = useCallback(async (formData: any) => {
		setLoading(true);
		let result: Result | null = await app.ajaxApi("PUT", `financeiro/contas-pagar/${contaSelecionada?.id}/confirmar-pagamento`, formData);
		if (result?.code === 200) {
			let itemAtualizado;
			let dadosAtualizados: any = null;

			eventoContasPagarState.forEach((conta: EventoContasPagar, index: number) => {
				if (conta.contasPagar.id === contaSelecionada?.id) {
					itemAtualizado = index;
					dadosAtualizados = conta;
				}
			});
			
			if (itemAtualizado !== undefined && dadosAtualizados !== null) {			
				dadosAtualizados.contasPagar = result.dados;
				setEventoContasPagarState(
					update(eventoContasPagarState, {
					$splice: [
							[itemAtualizado, 1],
							[itemAtualizado, 0, dadosAtualizados],
						],
					}),
				);
			}
		}
		setLoading(false);
		fecharModalConfirmarPagamento();
	}, [app, contaSelecionada, eventoContasPagarState, fecharModalConfirmarPagamento]);




	const desfazerPagamento = useCallback(async (contasPagar: ContasPagar) => {
		let result: Result | null = await app.ajaxApi("PUT", `financeiro/contas-pagar/${contasPagar.id}/desfazer-pagamento`);
		if (result?.code === 200) {
			let itemAtualizado;
			let dadosAtualizados: any = null;

			eventoContasPagarState.forEach((conta: EventoContasPagar, index: number) => {
				if (conta.contasPagar.id === contasPagar?.id) {
					itemAtualizado = index;
					dadosAtualizados = conta;
				}
			});
			
			if (itemAtualizado !== undefined && dadosAtualizados !== null) {			
				dadosAtualizados.contasPagar = result.dados;
				setEventoContasPagarState(
					update(eventoContasPagarState, {
					$splice: [
							[itemAtualizado, 1],
							[itemAtualizado, 0, dadosAtualizados],
						],
					}),
				);
			}
		}
	}, [app, eventoContasPagarState]);




	const excluirPagamento = useCallback(async (contasPagar: ContasPagar) => {
		let result: Result | null = await app.ajaxApi("DELETE", `financeiro/contas-pagar/${contasPagar.id}/evento/${eventoId}`);
		if (result?.code === 200) {
			let itemExcluido;			

			eventoContasPagarState.forEach((conta: EventoContasPagar, index: number) => {
				if (conta.contasPagar.id === contasPagar?.id)
					itemExcluido = index;
			});
			
			if (itemExcluido !== undefined) {							
				setEventoContasPagarState(
					update(eventoContasPagarState, {
						$splice: [
							[itemExcluido, 1], [itemExcluido, 0]
						]
					}),
				);
			}
		}
	}, [app, eventoContasPagarState, eventoId]);




	const modalEditar = useCallback(() => {
		setShowEditar(true);
		setContaSelecionada(null);
	}, []);

	const fecharEditar = useCallback(() => {
		setShowEditar(false);
		setContaSelecionada(null);
	}, []);

	const salvar = useCallback(async(formData: any) => {
		let dadosForm = {
			...formData,
			eventoId
		};

		setLoading(true);
		let result: Result | null = await app.ajaxApi("POST", `financeiro/contas-pagar/evento`, dadosForm);
		if (result?.code === 200) {
			let novoItem: EventoContasPagar = {
				eventoId: eventoId ? eventoId : '',
				contasPagarId: result.dados.id,
				contasPagar: result.dados,
				key: result.dados.id
			};

			setEventoContasPagarState(
				update(eventoContasPagarState, {
					$push: [novoItem]
				})
			);
		}

		setLoading(false);
		fecharEditar();
	}, [app, eventoId, eventoContasPagarState, fecharEditar]);




	useEffect(() => {
		if (eventoContasPagar)
			setEventoContasPagarState(eventoContasPagar);
	}, [eventoContasPagar]);


	return (
		<>
			<div className="box">
				<h2>Contas a Pagar</h2>

				<div className="submenu">
					<Button type='primary' className='button-success' onClick={modalEditar}>
						<FaPlus />
						Novo Pagamento
					</Button>
				</div>

				<Table columns={tabelaPagar} dataSource={eventoContasPagarState} />
			</div>


			<Modal closable maskClosable={false} footer={false} destroyOnClose visible={showConfirmarPagamento} onCancel={fecharModalConfirmarPagamento}>
				<h2>
					<FaCheck />
					Confirmar Pagamento
				</h2>

				<p>
					Você confirma o pagamento da conta: 
					<br />
					<strong>{contaSelecionada?.descricao}</strong>
					<br />
					<strong>R$ {NumberFormat(contaSelecionada?.valor, 2, ",", ".")}</strong>
				</p>

				<Form layout='vertical' onFinish={confirmarPagamento}>
					<FormItem label="Data de Pagamento" name="dataPagamento" rules={[
						{ required: true, message: "Informa a data" }
					]}>
						<DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
					</FormItem>

					<div className="acoes">
						<Button className='button-default' type='primary' icon={<FaBan />} onClick={fecharModalConfirmarPagamento}>Cancelar</Button>
						<Button className='button-success' type='primary' icon={<FaCheck />} htmlType="submit" loading={loading}>Salvar</Button>
					</div>
				</Form>
			</Modal>



			<Modal closable destroyOnClose maskClosable={false} footer={false} visible={showEditar} onCancel={fecharEditar}>
				<h2>
					<FaEdit />
					Editar Contas a Pagar
				</h2>

				<Form layout='vertical' onFinish={salvar}>
					<Row gutter={25}>
						<Col md={12}>
							<FormItem label="Data" name="data" rules={[
								{ required: true, message: "Informe a data" }
							]}>
								<DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
							</FormItem>
						</Col>

						<Col md={12}>
							<FormItem label="Status" name="contasPagarStatusId" rules={[
								{ required: true, message: "Informe o status" }
							]}>
								<Select style={{ width: '100%' }}>
									<Option value={1}>Pendente</Option>
									<Option value={2}>Pago</Option>
								</Select>
							</FormItem>							
						</Col>
					</Row>

					<FormItem label="Descrição" name="descricao" rules={[
						{ required: true, message: "Informe a descrição" }
					]}>
						<Input />
					</FormItem>

					<FormItem label="Valor" name="valor" rules={[
						{ required: true, message: "Informe o valor" }
					]}>
						<InputFloat />
					</FormItem>

					<FormItem label="Observações" name="obs">
						<TextArea rows={5} />
					</FormItem>

					<div className="acoes">
						<Button className='button-default' type='primary' icon={<FaBan />} onClick={fecharEditar}>Cancelar</Button>
						<Button className='button-success' type='primary' icon={<FaCheck />} htmlType="submit" loading={loading}>Salvar</Button>
					</div>
					
				</Form>
			</Modal>
		</>
	);
}

export default ContasPagarEvento;