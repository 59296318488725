import React from 'react';
import { Route, Routes as RoutesDOM } from "react-router-dom";
import LayoutAdmin from '../layouts/Admin/LayoutAdmin';
import LayoutBlank from '../layouts/Blank/LayoutBlank';
import LayoutParceiro from '../layouts/Parceiro/LayoutParceiro';
import Arquivos from '../areas/admin/pages/Arquivos/Arquivos';
import CadastroDetalhes from '../areas/admin/pages/Cadastros/CadastroDetalhes';
import Cadastros from '../areas/admin/pages/Cadastros/Cadastros';
import Eventos from '../areas/admin/pages/Eventos/Eventos';
import MeusDados from '../areas/global/pages/MeusDados/MeusDados';
import Painel from '../areas/admin/pages/Painel/Painel';
import Processos from '../areas/admin/pages/Processos/Processos';
import Login from '../areas/global/pages/Auth/Login';
import Inicio from '../areas/parceiro/pages/Inicio/Inicio';
import RenderPage from './RenderPage';
import Configuracoes from '../areas/admin/pages/Configuracoes/Configuracoes';
import Areas from '../areas/global/pages/Auth/Areas';
import AcessoNegado from '../areas/global/pages/AcessoNegado/AcessoNegado';
import EventoDetalhe from '../areas/admin/pages/Eventos/EventoDetalhe';
import ProcessosDetalhes from '../areas/admin/pages/Processos/ProcessosDetalhes';
import EsqueciMinhaSenha from '../areas/global/pages/Auth/EsqueciMinhaSenha';
import LayoutLogin from '../layouts/Login/LayoutLogin';
import RecuperaSenha from '../areas/global/pages/Auth/RecuperaSenha';

const Routes: React.FC = () => {

	return (
		<RoutesDOM>
			<Route path='/' element={<RenderPage component={Login} layout={LayoutLogin} />}></Route>
			<Route path='/auth/areas' element={<RenderPage component={Areas} layout={LayoutBlank} isPrivate />}></Route>
			<Route path='/auth/esqueci-minha-senha' element={<RenderPage component={EsqueciMinhaSenha} layout={LayoutLogin} />}></Route>
			<Route path='/auth/recupera-senha/:token' element={<RenderPage component={RecuperaSenha} layout={LayoutLogin} />}></Route>
			

			{/** ********** GLOBAL ********** */}
			


			{/** ********** ADMIN ********** */}
			<Route path='/admin/painel' element={<RenderPage component={Painel} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/configuracoes' element={<RenderPage component={Configuracoes} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/arquivos' element={<RenderPage component={Arquivos} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>			
			<Route path='/admin/cadastros' element={<RenderPage component={Cadastros} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/cadastro/detalhes/:id' element={<RenderPage component={CadastroDetalhes} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			
			<Route path='/admin/processos' element={<RenderPage component={Processos} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/processos/detalhes/:id' element={<RenderPage component={ProcessosDetalhes} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>

			<Route path='/admin/eventos' element={<RenderPage component={Eventos} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/eventos/detalhes/:id' element={<RenderPage component={EventoDetalhe} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/meus-dados' element={<RenderPage component={MeusDados} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			

			{/** ********** PARCEIRO ********** */}
			<Route path='/parceiro/inicio' element={<RenderPage component={Inicio} isPrivate areas={[ "Parceiro" ]} layout={LayoutParceiro} />}></Route>
			<Route path='/meus-dados' element={<RenderPage component={MeusDados} isPrivate areas={[ "Parceiro" ]} layout={LayoutParceiro} />}></Route>

			



			<Route path='/acesso-negado' element={<RenderPage component={AcessoNegado} layout={LayoutBlank} />}></Route>
			



		</RoutesDOM>
	);
}

export default Routes;