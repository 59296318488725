import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { MdOutlineEventAvailable } from 'react-icons/md';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';



const EventoDashboard: React.FC = () => {

	const app = useApp();	
	const [pieData, setPieData] = useState<any>();
	const optionsChart = {
		colors: ["#a0d4fb", "#eeb041", '#95ce5a', "#444444", "#e26d64"]
	}


	


	const carregaDashboard = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("get", "admin/evento/dashboard");
		if (result?.code === 200) {
			let data = [
				["Status", "Quantidade"],
				["Novos", result?.dados.novos],
				["Pendentes", result?.dados.pendentes],
				["Confirmados", result?.dados.confirmados],
				["Concluídos", result?.dados.conluidos],
				["Cancelados", result?.dados.cancelados],
			];
			setPieData(data);
		}
	}, [app]);


	useEffect(() => {
		carregaDashboard();
	}, [carregaDashboard]);


	return (
		<div className="widget">
			<h2>
				<MdOutlineEventAvailable />
				Eventos
			</h2>

			{pieData && <Chart chartType='PieChart' data={pieData} options={optionsChart} />}
		</div>
	);
}

export default EventoDashboard;