import { Button, Col, notification, Popconfirm, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowLeft, FaTrash } from 'react-icons/fa';
import { MdOutlineEventAvailable } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import EventoStatus from '../../../../components/EventoStatus';
import { Evento } from '../../../../entities/evento';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import ClienteResumo from '../../components/ClienteResumo';
import ContasPagarEvento from '../../components/ContasPagarEvento';
import ContasReceberEvento from '../../components/ContasReceberEvento';
import EventoArquivos from '../../components/EventoArquivos';
import EventoEditar from '../../components/EventoEditar';
import ParceiroResumo from '../../components/ParceiroResumo';



const EventoDetalhe: React.FC = () => {

	const { id } = useParams();
	const app = useApp();

	const [evento, setEvento] = useState<Evento>();


	const carregaEvento = useCallback(async () => {
		try {
			let result: Result | null = await app.ajaxApi('get', `admin/evento/${id}`);			
			setEvento(result?.dados);			
		} catch (e) {
			console.log(e);
		}
	}, [id, app]);


	const successEventoEditar = useCallback((evento: Evento) => {		
		carregaEvento();
	}, [carregaEvento]);	


	const atualizaEvento = useCallback((evento: Evento) => {		
		carregaEvento();
	}, [carregaEvento]);	


	const excluirEvento = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("delete", `admin/evento/${evento?.id}`);
		if (result?.code === 200) {
			notification.success({
				message: "Evento excluído",
				duration: 5,
				placement: 'bottomRight'
			});

			app.goBack();
		}
	}, [evento, app]);


	useEffect(() => {		
		carregaEvento();
	}, [id, carregaEvento]);


  return (
	<>
		<h1 style={{ display: 'flex', alignItems: 'center' }}>			
			<MdOutlineEventAvailable />
			Detalhes do Evento
			
			<div style={{ marginLeft: '25px' }}>
				<EventoStatus size='large' eventoStatusId={evento?.eventoStatusId} eventoStatusNome={evento?.eventoStatusNome} />
			</div>
		</h1>

		<div className="submenu">

			<Button icon={<FaArrowLeft />} onClick={app.goBack}>Voltar</Button>
			
			<Popconfirm  placement="bottomLeft" title="Você confirma a EXCLUSÃO deste evento?" onConfirm={excluirEvento}>
				<Button icon={<FaTrash />} danger type='primary'>Excluir Evento</Button>
			</Popconfirm>

			
		</div>

		<Row gutter={25}>
			<Col md={16}>
				<EventoEditar evento={evento} editable onSuccess={successEventoEditar} />
			</Col>

			<Col md={8}>							
				<ClienteResumo cadastroCliente={evento?.cadastroCliente} />

				<ParceiroResumo cadastroParceiro={evento?.cadastroParceiro} />
			</Col>

			<Col md={24}>
				<EventoArquivos evento={evento} onSuccess={atualizaEvento} permiteExcluir />
			</Col>

			<Col md={12}>
				<ContasReceberEvento eventoId={evento?.id} eventoContasReceber={evento?.eventoContasReceber} />
			</Col>

			<Col md={12}>
				<ContasPagarEvento eventoId={evento?.id} eventoContasPagar={evento?.eventoContasPagar} />
			</Col>
		</Row>
	</>
  );
}

export default EventoDetalhe;