import { Button, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { MdTimeline } from 'react-icons/md';
import { Processo } from '../../../../entities/processo';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import ProcessoNovo from '../../components/ProcessoNovo';

const Processos: React.FC = () => {

	const app = useApp();

	const [processos, setProcessos] = useState<Processo[]>([]);
	const [showProcessoEditar, setShowProcessoEditar] = useState(false);

	const tabelaProcessos: ColumnsType<Processo> = [
		{
			title: "Código",
			dataIndex: "codigo"
		},
		{
			title: "Nome",
			dataIndex: "nome"
		},
		{
			render: (processo: Processo) => (
				<>
					<Button type='primary' className='button-default' onClick={() => app.toUrl(`/admin/processos/detalhes/${processo.id}`)}>
						<FaSearch />
						Detalhes
					</Button>
				</>
			)
		}
	];


	const carregaProcessos = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("get", "processo");
		if (result?.code === 200)
			setProcessos(result.dados);		
	}, [app]);


	useEffect(() => {
		carregaProcessos();
	}, [carregaProcessos]);


	return (
		<>
			<h1>
				<MdTimeline />
				Processos
		  	</h1>

			<div className="submenu">
				<Button type='primary' className='button-success' onClick={() => setShowProcessoEditar(true)}>
					<FaPlus />
					Novo Processo
				</Button>
			</div>

			<Table columns={tabelaProcessos} dataSource={processos} />

			<Modal visible={showProcessoEditar} footer={false} closable maskClosable={false} destroyOnClose onCancel={() => setShowProcessoEditar(false)}>
				<ProcessoNovo onClose={() => setShowProcessoEditar(false)} onSuccess={carregaProcessos} />
			</Modal>
		</>
	);
}

export default Processos;